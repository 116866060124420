import { useField, Field } from 'formik';
import type { FC } from 'react';

interface InputFieldProps {
  name: string;
  placeHolder: string;
}

const InputField: FC<InputFieldProps> = ({ name, placeHolder }) => {
  const [field] = useField<any>(name);

  return (
    <Field
      className="input-field"
      type="text"
      placeholder={placeHolder}
      {...field}
      autoComplete="off"
    />
  );
};

export default InputField;
