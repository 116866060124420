/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { encodeURL, TransactionRequestURLFields } from '@solana/pay';
import React, { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

type Props = {};

const AppSolanaPay: FC<Props> = () => {
  const [params] = useSearchParams();
  const reference: string | undefined | null | any = params.get('reference');
  const amount: string | undefined | null | any = params.get('amount');
  const label: string | undefined | null | any = params.get('label');
  const message: string | undefined | null | any = params.get('message');

  useEffect(() => {
    if (
      reference !== null &&
      amount !== null &&
      label !== null &&
      message !== null
    ) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const transactionUrl = `https://mobile.scalex.africa/solana-pay?reference=${reference}&amount=${amount}`;

      const urlParams: TransactionRequestURLFields = {
        link: new URL(transactionUrl),
        label,
        message,
      };

      const encodedURL = encodeURL(urlParams);
      console.log('🚀 ~ useEffect ~ encodedURL:', encodedURL);

      window.location.href = encodedURL.href;
    }
  }, [reference, amount, label, message]);

  return (
    <Layout>
      <div>AppSolanaPay</div>;
    </Layout>
  );
};

export default AppSolanaPay;
