import type { FC } from 'react';
import ReactGA from 'react-ga';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import Layout from '../../components/Layout/Layout';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import { cautionIcon, errorEmail, errorEmailBg } from '../../assets';
import PageLogo from '../../components/PageLogo/PageLogo';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

const InvalidEmail: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state?.data;
  const rampType = location.state?.rampType;

  return (
    <ScrollToTop>
      <Layout>
        <div className="invalid-email">
          <TopPageTransactionInfo
            title={'PAYING'}
            rampType={rampType}
            amount={
              rampType === 'on'
                ? parseFloat(data?.currencyAmount).toLocaleString()
                : parseFloat(data?.amount)
                    .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                    .toLocaleString()
            }
            token={rampType === 'on' ? data?.currency : data?.token}
          />
          <div className="top-welcome-page-content">
            <PageLogo
              iconBg={errorEmailBg}
              icon={errorEmail}
              floatIcon={cautionIcon}
            />
            <h1 className="welcome-message">
              Verify your email address to continue
            </h1>
          </div>
          <PrimaryButton
            title="Try again"
            type="submit"
            validForm={true}
            onClick={() => {
              navigate('/verify-email');
              ReactGA.event({
                category: 'retry email verification',
                action: 'retry email verification button',
                label: 'retry_email_verification',
              });
            }}
            style={{ maxWidth: '251px', margin: '24px auto' }}
          />{' '}
        </div>
      </Layout>
    </ScrollToTop>
  );
};

export default InvalidEmail;
