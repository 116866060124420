import React from 'react';

const LoadingComponent = () => (
  <div className="loading-container">
    <div className="dot">{''}</div>
    <div className="dot">{''}</div>
    <div className="dot">{''}</div>
  </div>
);

export default LoadingComponent;
