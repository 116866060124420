import { useRef, useState } from 'react';
import type { FC } from 'react';
import { chevron, offRampCheck, searchIcon } from '../../../assets';
import useEscapeKey from '../../../hooks/useEscapeKey';
import useOutsideClick from '../../../hooks/useOutsideClick';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';

interface bankProp {
  name: string;
  code: string;
}
interface SelectBankProps {
  setFieldValue: any;
  title: string;
  reIntValue: string;
  requestError: any;
  bankList: bankProp[];
  name?: string;
}

const SelectBank: FC<SelectBankProps> = ({
  title,
  setFieldValue,
  bankList,
  reIntValue,
  // requestError,
}) => {
  const ref = useRef(null);
  const [modal, setModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedBank, setSelectedBank] = useState<any>();

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleClose = () => {
    setModal(false);
  };

  useEscapeKey(handleClose);
  useOutsideClick(handleClose, ref);

  return (
    <div ref={ref} className="select-bank-field-container">
      <p className="title"> {title} </p>
      <div
        className={`field ${modal ? 'active-select-field' : ''}`}
        onClick={toggleModal}
      >
        <>
          {selectedBank !== undefined && typeof selectedBank !== 'string' ? (
            <p className="field-title"> {selectedBank?.name} </p>
          ) : reIntValue !== null && reIntValue !== undefined ? (
            <p> {reIntValue} </p>
          ) : (
            <p className="place-holder">{title}</p>
          )}
        </>
        <img
          className={modal ? 'rotate' : 'chevron'}
          src={chevron}
          alt="chevron"
        />{' '}
      </div>

      <div className={`list-modal ${!modal ? 'close-data-modal' : ''}`}>
        <input
          type="text"
          value={searchQuery}
          placeholder="search bank"
          onChange={(e: any) => {
            setSearchQuery(e.target.value);
          }}
        />
        <img className="search-icon" src={searchIcon} alt="" />

        <p className="search-title">COMMERCIAL BANKS</p>

        <div className="list-container">
          {bankList?.length < 1 ? (
            <LoadingComponent />
          ) : (
            bankList
              ?.filter(
                (obj: any) =>
                  searchQuery === ' ' ||
                  obj?.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
              )
              .map((obj: any, i: any) => (
                <div
                  key={i}
                  onClick={() => {
                    setFieldValue('bank', obj?.name, false);
                    setFieldValue('bankCode', obj.code, false);

                    setSelectedBank(obj);
                    handleClose();
                  }}
                  className="list"
                >
                  <p
                    style={
                      selectedBank?.code === obj?.code
                        ? { color: '#946CFF' }
                        : { color: '' }
                    }
                  >
                    {obj.name}
                  </p>
                  {selectedBank?.code === obj?.code && (
                    <img src={offRampCheck} alt="" />
                  )}
                </div>
              ))
          )}
        </div>

        <div className="bottom-blur"> {''} </div>
      </div>
    </div>
  );
};

export default SelectBank;
