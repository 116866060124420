/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import Layout from '../../components/Layout/Layout';
import { emailIcon, errorIcon, logo, logoBg } from '../../assets';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import EmailField from '../../components/Fields/EmailField/EmailField';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ReactGA from 'react-ga';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import { connect, useSelector } from 'react-redux';
import { _VALIDATE_EMAIL } from '../../redux/transaction/transactionActions';
import PageLogo from '../../components/PageLogo/PageLogo';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import FieldStatus from '../../components/Fields/FieldStatus/FieldStatus';

interface Props {
  _VALIDATE_EMAIL: (
    email: string,
    rampType: string,
    data: any,
    navigate: any,
    setButtonDisabled: (v: boolean) => void
  ) => void;
}

const VerifyEmail: FC<Props> = ({ _VALIDATE_EMAIL }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state?.data;
  const rampType = location.state?.rampType;

  const existingEmail: string | null | any = localStorage.getItem('email');

  const [form, setForm] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(true);

  const onAndOffRampError = useSelector(
    (state: any) => state.transaction.onAndOffRampError
  );

  const validateEmailError = useSelector(
    (state: any) => state.transaction.validateEmailError
  );

  const [activeButton, setActiveButton] = useState<boolean>(true);

  const text =
    !checking && form
      ? 'Enter your email address'
      : !form && !checking
      ? 'Verify your email address to continue'
      : 'Ramp is checking if your identity is validated';

  useEffect(() => {
    setTimeout(() => {
      setChecking(false);
      setForm(true);
    }, 3000);
  }, []);

  return (
    <Layout>
      <ScrollToTop>
        <TopPageTransactionInfo
          title={'PAYING'}
          rampType={rampType}
          amount={
            rampType === 'on'
              ? parseFloat(data?.currencyAmount).toLocaleString()
              : parseFloat(data?.amount)
                  .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                  .toLocaleString()
          }
          token={rampType === 'on' ? data?.currency : data?.token}
        />

        <div className="top-welcome-page-content">
          <PageLogo iconBg={logoBg} icon={logo} />
          <h1 className="welcome-message">{text}</h1>
          {checking && <p>Kindly wait for a few seconds</p>}
          {checking && <LoadingComponent />}
        </div>

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            email: existingEmail !== null ? existingEmail : '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Email is required'),
          })}
          onSubmit={(values, { setErrors }) => {
            const { email } = values;
            const errors = {};
            if (
              Object.entries(errors).length === 0 &&
              errors.constructor === Object
            ) {
              setActiveButton(false);
              localStorage.setItem('email', email);
              localStorage.setItem('bankEmail', email);
              _VALIDATE_EMAIL(email, rampType, data, navigate, setActiveButton);
              ReactGA.event({
                category: 'verify-email',
                action: 'verify email button',
                label: 'verify_email_button',
              });
            } else {
              setErrors(errors);
            }
          }}
        >
          {({ handleSubmit, isValid }) => (
            <Form onSubmit={handleSubmit}>
              {!checking && (
                <div className="verify-email-content">
                  <div>
                    <EmailField
                      name={'email'}
                      title={'Email Address'}
                      icon={emailIcon}
                    />

                    <PrimaryButton
                      title="Verify email address"
                      type="submit"
                      activeButton={activeButton}
                      validForm={isValid}
                      onClick={() => {}}
                    />

                    {(onAndOffRampError !== null ||
                      validateEmailError !== null) && (
                      <FieldStatus
                        icon={errorIcon}
                        mode={'error'}
                        errorMessage={
                          validateEmailError !== null
                            ? validateEmailError
                            : onAndOffRampError
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </ScrollToTop>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  _VALIDATE_EMAIL: (
    email: string,
    rampType: string,
    data: any,
    navigate: any,
    setActiveButton: (v: any) => void
  ) =>
    dispatch(_VALIDATE_EMAIL(email, rampType, data, navigate, setActiveButton)),
});

export default connect(null, mapDispatchToProps)(VerifyEmail);
