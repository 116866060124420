import type { FC } from 'react';
import { logo } from '../../assets';
import PageLogo from '../PageLogo/PageLogo';

interface Props {
  type?: boolean;
  text: string;
  subTitle?: string;
  icon?: string;
  iconBg?: string;
  titleStyle?: any;
}

const PageInfo: FC<Props> = ({
  text,
  subTitle,
  icon,
  iconBg,
  titleStyle,
  type,
}) => (
  <div className="top-welcome-page-content">
    <PageLogo
      iconBg={iconBg}
      icon={icon !== undefined ? icon : logo}
      type={type}
    />
    <div className="loading-content">
      <h1 style={titleStyle} className="welcome-message">
        {text}
      </h1>
      {subTitle !== undefined && <p>{subTitle}</p>}
    </div>
  </div>
);
export default PageInfo;
