import type { FC } from 'react';
import PageInfo from '../PageInfo/PageInfo';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

interface Props {
  title?: string;
}

const LoadingState: FC<Props> = ({ title }) => (
  <div className="welcome-page">
    <PageInfo text={title !== undefined ? title : 'Opening Scalex Ramp'} />
    <LoadingComponent />
  </div>
);
export default LoadingState;
