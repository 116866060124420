import FieldPosition from '../../FieldPosition/FieldPosition';
import WalletAddressInput from '../WalletAddressInput/WalletAddressInput';

import type { FC } from 'react';

export interface WalletAddressProps {
  name: string;
  title: string;
  value: string;
  network: string;
  address: string;
  isDisabled: boolean;
  lastStage?: boolean;
  currentStage: number;
  fieldPosition: number;
  status: boolean | undefined;
  setStatus: (v: boolean) => void;
  setFieldValue: (value: string) => void;
  _VALIDATE_WALLET_ADDRESS: (
    address: string,
    network: string,
    setStatus: (v: boolean) => void
  ) => void;
}

const WalletAddressField: FC<WalletAddressProps> = ({
  title,
  lastStage,
  currentStage,
  fieldPosition,
  setFieldValue,
  value,
  name,
  isDisabled,
  status,
  setStatus,
  address,
  network,
  _VALIDATE_WALLET_ADDRESS,
}) => (
  <div className="select-field-container amount-input-container">
    <FieldPosition
      lastStage={lastStage}
      fieldPosition={fieldPosition}
      currentStage={currentStage}
    />

    <div
      className={`field-container ${
        currentStage === fieldPosition ? 'no-border' : ''
      } `}
    >
      <p
        className={`title ${
          currentStage < fieldPosition ? 'in-active-field' : ''
        }`}
      >
        {title}
      </p>

      <WalletAddressInput
        title={title}
        name={name}
        value={value}
        status={status}
        setStatus={setStatus}
        network={network}
        address={address}
        isDisabled={isDisabled}
        currentStage={currentStage}
        fieldPosition={fieldPosition}
        setFieldValue={setFieldValue}
        _VALIDATE_WALLET_ADDRESS={_VALIDATE_WALLET_ADDRESS}
      />
    </div>
  </div>
);

export default WalletAddressField;
