import { useEffect, type FC } from 'react';
import PageInfo from '../PageInfo/PageInfo';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { footerLine } from '../../assets';
import PReceivedTimer from '../PReceivedTimer/PReceivedTimer';

interface TransactionResponseProps {
  iconBg: string;
  icon: string;
  status?:
    | 'ramp_failed'
    | 'payment_reversed'
    | 'kyc_failed'
    | 'tier_pending'
    | '500';
  titleStyle?: any;
  statusText: string;
  description: string;
  buttonTitle?: string;
  descriptionStyle?: any;
  transactionData?: any;
  buttonAction?: () => void;
  rampType: 'on' | 'off';
  showTimer?: boolean;
  redirectUrl?: string;
}

const TransactionResponse: FC<TransactionResponseProps> = ({
  iconBg,
  icon,
  status,
  showTimer,
  rampType,
  statusText,
  redirectUrl,
  description,
  titleStyle,
  buttonTitle,
  buttonAction,
  descriptionStyle,
  transactionData,
}) => {
  const navigate = useNavigate();
  const backUrl =
    status === 'tier_pending' && rampType === 'on'
      ? '/on-ramp'
      : status === 'tier_pending' && rampType !== 'on'
      ? '/off-ramp'
      : '/';
  const data =
    status === 'ramp_failed' ||
    status === 'payment_reversed' ||
    status === 'tier_pending'
      ? transactionData
      : null;
  const stage =
    (status === 'ramp_failed' ||
      status === 'payment_reversed' ||
      status === 'tier_pending') &&
    rampType === 'on'
      ? 5
      : (status === 'ramp_failed' ||
          status === 'payment_reversed' ||
          status === 'tier_pending') &&
        rampType === 'off'
      ? 4
      : null;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        redirectUrl !== undefined &&
        redirectUrl !== null &&
        redirectUrl !== 'null'
      ) {
        window.location.href = redirectUrl;
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [redirectUrl]);

  return (
    <div className="success-transaction">
      <PageInfo
        type={true}
        titleStyle={titleStyle}
        iconBg={iconBg}
        icon={icon}
        text={statusText}
      />

      <p style={descriptionStyle} className="description">
        {description}{' '}
      </p>

      {(showTimer ?? false) && <PReceivedTimer />}

      <PrimaryButton
        validForm={null}
        type={'button'}
        title={buttonTitle !== undefined ? buttonTitle : 'Close'}
        onClick={() => {
          if (status === 'kyc_failed' && buttonAction !== undefined) {
            buttonAction();
          } else if (status === '500' && buttonAction !== undefined) {
            navigate('/');
            buttonAction();
          } else {
            navigate(backUrl, {
              state: { data, stage, loading: false },
            });
          }
        }}
        style={{
          background: ' #F3F5F7',
          color: '#191C1F',
          width: 'fit-content',
        }}
      />

      {status !== '500' && (
        <div className="footer-box">
          <img src={footerLine} alt="footer-line" />
          <p className="email">
            Email us at{' '}
            <a href="mailto:support@scalex.africa" target="blank">
              support@scalex.africa
            </a>{' '}
            for prompt assistance with any concern.
          </p>
        </div>
      )}
    </div>
  );
};
export default TransactionResponse;
