import type { FC } from 'react';
import { BtnLoader } from '../../assets';

interface PrimaryButtonProps {
  validForm: boolean | undefined | null;
  type: 'button' | 'submit';
  title: string;
  onClick?: () => void;
  style?: any;
  activeButton?: boolean;
  icon?: string;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  validForm,
  title,
  type,
  onClick,
  style,
  icon,
  activeButton,
}) => (
  <>
    <button
      className={`primary-button ${
        validForm === null ||
        ((validForm ?? false) &&
          (activeButton === true || activeButton === undefined))
          ? 'enabled-primary-button'
          : ''
      }`}
      type={type}
      onClick={onClick}
      style={style}
      disabled={!(validForm === null || validForm === true)}
    >
      {icon !== undefined && (
        <img src={icon} alt="logo" style={{ width: 24, height: 24 }} />
      )}
      {activeButton === true || activeButton === undefined ? (
        <span
          style={
            style !== undefined ? { color: style.color } : { fontWeight: '500' }
          }
        >
          {title}
        </span>
      ) : (
        <img src={BtnLoader} alt="btn" style={{ width: 32 }} />
      )}
    </button>
  </>
);

export default PrimaryButton;
