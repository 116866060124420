/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState, useEffect } from 'react';
import type { FC } from 'react';
import Layout from '../../components/Layout/Layout';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import PageInfo from '../../components/PageInfo/PageInfo';
import {
  failedBg,
  failedIcon,
  logo,
  reversedBg,
  reversedIcon,
  successBg,
  successIcon,
} from '../../assets';
import TransactionResponse from '../../components/TransactionResponse/TransactionResponse';
import { useLocation } from 'react-router-dom';
import Pusher from 'pusher-js';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

const TransactionStatus: FC = () => {
  const location = useLocation();
  const data: any = location.state?.data;
  const rampStatus = location.state.status;
  const title = location.state?.transactionTitle;
  const message = location.state?.transactionMessage;
  const rampType: 'on' | 'off' = location.state?.rampType;
  const reference = location.state?.reference;
  const redirect = location.state?.redirectUrl;

  const [transactionMessage, setTransactionMessage] = useState<string>(
    message !== undefined ? message : null
  );
  const [transactionTitle, setTransactionTitle] = useState<string>(
    title !== undefined ? title : null
  );
  const [redirectUrl, setRedirectUrl] = useState<any>(redirect);

  const [status, setStatus] = useState<
    | 'ramp_failed'
    | 'ramp_successful'
    | 'kyc_failed'
    | 'payment_reversed'
    | 'payment_received'
    | 'tier_pending'
    | 'payment_delayed'
    | null
  >(rampStatus !== undefined ? rampStatus : null);

  useEffect(() => {}, [rampStatus]);

  const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
  });

  const channel = pusher.subscribe(`scalex_ramp_${reference}`); // channel to subscribe to which is unique to each user

  channel.bind('ramp_successful', function (e: any) {
    setStatus('ramp_successful');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  channel.bind('ramp_failed', function (e: any) {
    setStatus('ramp_failed');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  channel.bind('payment_reversed', function (e: any) {
    setStatus('payment_reversed');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  channel.bind('payment_received', function (e: any) {
    setStatus('payment_received');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
  });

  channel.bind('payment_delayed', function (e: any) {
    setStatus('payment_delayed');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
  });

  return (
    <Layout>
      <div className="transaction-status">
        {(status === null || status === undefined) && (
          <TopPageTransactionInfo
            title={'PAYING'}
            rampType={rampType}
            amount={
              rampType === 'on'
                ? parseFloat(data?.currencyAmount).toLocaleString()
                : parseFloat(data?.amount)
                    .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                    .toLocaleString()
            }
            token={rampType === 'on' ? data?.currency : data?.token}
          />
        )}
        {(status === null || status === undefined) && (
          <div className="welcome-page">
            <PageInfo text={'Checking transaction status'} />
            <LoadingComponent />
          </div>
        )}

        {status === 'tier_pending' && (
          <TransactionResponse
            iconBg={successBg}
            icon={logo}
            statusText={'Transaction in progress'}
            description={
              'Your transaction is currently pending. Please wait while we complete the process.'
            }
            rampType={rampType}
            titleStyle={{ marginTop: '-20px' }}
            descriptionStyle={{ width: '223px' }}
            transactionData={data}
            status={status}
          />
        )}

        {status === 'payment_delayed' && (
          <TransactionResponse
            iconBg={successBg}
            icon={logo}
            statusText={transactionTitle}
            description={transactionMessage}
            rampType={rampType}
            titleStyle={{ marginTop: '-20px' }}
            descriptionStyle={{ width: '223px' }}
          />
        )}

        {status === 'ramp_successful' && (
          <TransactionResponse
            redirectUrl={redirectUrl}
            iconBg={successBg}
            icon={successIcon}
            statusText={transactionTitle}
            description={transactionMessage}
            rampType={rampType}
            titleStyle={{ marginTop: '-20px' }}
            descriptionStyle={{ width: '223px' }}
          />
        )}

        {status === 'payment_received' && (
          <TransactionResponse
            iconBg={successBg}
            icon={successIcon}
            showTimer={true}
            statusText={transactionTitle}
            description={transactionMessage}
            rampType={rampType}
            titleStyle={{ marginTop: '-20px' }}
            descriptionStyle={{ width: '223px' }}
          />
        )}

        {status === 'ramp_failed' && (
          <TransactionResponse
            redirectUrl={redirectUrl}
            status={status}
            iconBg={failedBg}
            icon={failedIcon}
            rampType={rampType}
            buttonTitle="Try again"
            titleStyle={{ width: '260px', marginTop: '-20px' }}
            transactionData={data}
            descriptionStyle={{ width: '260px' }}
            statusText={transactionTitle}
            description={transactionMessage}
          />
        )}

        {status === 'kyc_failed' && (
          <TransactionResponse
            status={status}
            iconBg={failedBg}
            icon={failedIcon}
            rampType={rampType}
            buttonTitle="Try again"
            titleStyle={{ width: '260px', marginTop: '-20px' }}
            transactionData={data}
            descriptionStyle={{ width: '260px' }}
            statusText={'We couldn’t verify your ID'}
            description={''}
          />
        )}

        {status === 'payment_reversed' && (
          <TransactionResponse
            redirectUrl={redirectUrl}
            status={status}
            iconBg={reversedBg}
            icon={reversedIcon}
            rampType={rampType}
            buttonTitle="Try again"
            titleStyle={{ width: '260px', marginTop: '-20px' }}
            transactionData={data}
            descriptionStyle={{ width: '260px' }}
            statusText={transactionTitle}
            description={transactionMessage}
          />
        )}
      </div>
    </Layout>
  );
};

export default TransactionStatus;
