/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-shadow */
import { useState, type FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import PageLogo from '../../components/PageLogo/PageLogo';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { Form, Formik } from 'formik';
import ReactGA from 'react-ga';
import * as Yup from 'yup';
import PhoneField from '../../components/Fields/PhoneField/PhoneField';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { connect, useDispatch, useSelector } from 'react-redux';
import { errorIcon, logo, logoBg } from '../../assets';
import { createStructuredSelector } from 'reselect';
import {
  _GET_SUPPORTED_COUNTRIES,
  _KYC_TIER_ONE,
} from '../../redux/transaction/transactionActions';
import {
  selectKYCTierOne,
  selectKYCTierOneError,
} from '../../redux/transaction/transactionSelectors';
import type { KYCTierOne } from '../../../types';
import FieldStatus from '../../components/Fields/FieldStatus/FieldStatus';

interface Props {
  kycTierOne: KYCTierOne;
  kycTierOneError: any;
  _KYC_TIER_ONE: (
    basicData: any,
    data: any,
    email: string,
    phoneNumber: string,
    country: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setActiveButton: (v: boolean) => void
  ) => void;
}

const PhoneVerification: FC<Props> = ({ kycTierOneError, _KYC_TIER_ONE }) => {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const supportedCountries = useSelector(
    (state: any) => state.transaction.supportedCountries
  );

  const data = location.state?.data;

  const basicData = location.state?.basicData;
  const rampType = location.state?.rampType;

  const goToTier = location.state?.goToTier;

  const [kycData] = useState<any>();

  const existingEmail: any = localStorage.getItem('email');

  const [form, setForm] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(true);

  const [activeButton, setActiveButton] = useState<boolean>(true);

  const text =
    !checking && form
      ? 'Enter your phone number'
      : 'Ramp is checking if your identity is validated';

  useEffect(() => {
    setTimeout(() => {
      setChecking(false);
      setForm(true);
    }, 2000);
  }, []);

  useEffect(() => {
    dispatch(_GET_SUPPORTED_COUNTRIES());
  }, [dispatch]);

  return (
    <Layout>
      <ScrollToTop>
        <TopPageTransactionInfo
          title={'PAYING'}
          rampType={rampType}
          amount={
            rampType === 'on'
              ? parseFloat(data?.currencyAmount).toLocaleString()
              : parseFloat(data?.amount)
                  .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                  .toLocaleString()
          }
          token={rampType === 'on' ? data?.currency : data?.token}
        />

        <div className="top-welcome-page-content">
          <PageLogo iconBg={logoBg} icon={logo} />
          <h1 className="welcome-message">{text}</h1>
          {checking && <p>Kindly wait for a few seconds</p>}
          {checking && <LoadingComponent />}
        </div>

        {form && !checking && (
          <Formik
            validateOnMount
            key={'phoneField'}
            initialValues={{
              country: 'NG',
              code: kycData !== undefined ? kycData.phone_number?.slice(4) : '',
              phoneNumber: '',
            }}
            validationSchema={Yup.object({
              country: Yup.string().required(),
              phoneNumber: Yup.string()
                .min(9, 'Invalid phone number')
                .max(14, 'Invalid phone number')
                .required('This is a required field'),
            })}
            onSubmit={(values, { setErrors }) => {
              const { phoneNumber, country } = values;
              const errors = {};
              if (
                Object.entries(errors).length === 0 &&
                errors.constructor === Object
              ) {
                setActiveButton(false);
                _KYC_TIER_ONE(
                  basicData,
                  data,
                  existingEmail,
                  phoneNumber,
                  country,
                  rampType,
                  goToTier,
                  navigate,
                  setActiveButton
                );
                ReactGA.event({
                  category: 'phone verification',
                  action: 'phone verification button click',
                  label: 'phone_verification_button',
                });
              } else {
                setErrors(errors);
              }
            }}
          >
            {({ handleSubmit, isValid, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                {!checking && (
                  <div className="verify-email-content">
                    <div>
                      <PhoneField
                        name={'code'}
                        data={supportedCountries}
                        placeHolder={'Enter phone number'}
                        setFieldValue={setFieldValue}
                      />

                      <PrimaryButton
                        title="Verify phone number"
                        type="submit"
                        validForm={isValid}
                        activeButton={activeButton}
                      />

                      {kycTierOneError !== null && (
                        <FieldStatus
                          icon={errorIcon}
                          mode={'error'}
                          errorMessage={kycTierOneError}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        )}
      </ScrollToTop>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  kycTierOne: selectKYCTierOne,
  kycTierOneError: selectKYCTierOneError,
});

const mapDispatchToProps = (dispatch: any) => ({
  _KYC_TIER_ONE: (
    basicData: any,
    data: any,
    email: string,
    phoneNumber: string,
    country: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setActiveButton: (v: boolean) => void
  ) =>
    dispatch(
      _KYC_TIER_ONE(
        basicData,
        data,
        email,
        phoneNumber,
        country,
        rampType,
        goToTier,
        navigate,
        setActiveButton
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerification);
