import { Fragment, useState, useRef, useEffect } from 'react';
import type { FC } from 'react';
import { chevron, searchIcon } from '../../../assets';
import useEscapeKey from '../../../hooks/useEscapeKey';
import useOutsideClick from '../../../hooks/useOutsideClick';
import ImageComponent from '../../ImageComponent/ImageComponent';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';

interface SelectProps {
  data: any;
  name: string;
  title: string;
  type?: string;
  reInitValue?: string | undefined;
  className?: string;
  setFieldValue: any;
  setIdDetails?: (value: object) => void;
}

const Select: FC<SelectProps> = ({
  title,
  data,
  name,
  className,
  reInitValue,
  setIdDetails,
  setFieldValue,
}) => {
  const ref = useRef(null);
  const [modal, setModal] = useState<boolean>(false);
  const [selectedItem, setSelected] = useState<any>(null);
  const [returnedValue, setReturnedValue] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    const value = data.find((obj: any) =>
      obj.identifier !== undefined ? obj.identifier : obj === reInitValue
    );
    setReturnedValue(value);
  }, [data, reInitValue]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleClose = () => {
    setModal(false);
  };

  useEscapeKey(handleClose);
  useOutsideClick(handleClose, ref);

  return (
    <div className="select" style={{ width: '100%' }} ref={ref}>
      <div
        className={`select-field ${modal ? 'active-select-field' : ''}`}
        onClick={toggleModal}
      >
        <div className="field-title-container">
          {selectedItem?.logo !== undefined && (
            <ImageComponent size="m" url={selectedItem?.logo} />
          )}
          {/* {returnedValue?.logo !== undefined && (
            <ImageComponent size="m" url={returnedValue?.logo} />
          )} */}
          <Fragment>
            {selectedItem !== null ? (
              <p className="field-title">
                {selectedItem.fullname !== undefined
                  ? selectedItem.fullname
                  : selectedItem.name !== undefined
                  ? selectedItem.name
                  : selectedItem}{' '}
              </p>
            ) : reInitValue !== undefined ? (
              <p className="field-title">
                {returnedValue?.fullname !== undefined
                  ? returnedValue?.fullname
                  : returnedValue?.name !== undefined
                  ? returnedValue?.name
                  : returnedValue}
              </p>
            ) : (
              <p className="place-holder">{title} </p>
            )}
          </Fragment>
        </div>
        <img
          className={modal ? 'rotate' : 'chevron'}
          src={chevron}
          alt="chevron"
        />
      </div>

      <div
        className={`data-modal ${
          !modal ? 'close-data-modal' : ''
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        } ${className} `}
      >
        {(name === 'state' || name === 'localGovt') && (
          <>
            <input
              type="text"
              value={searchQuery}
              placeholder="search "
              onChange={(e: any) => {
                setSearchQuery(e.target.value);
              }}
            />
            <img className="search-icon" src={searchIcon} alt="" />
          </>
        )}
        {data?.length < 1 ? (
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <LoadingComponent />
          </div>
        ) : (
          data
            ?.filter((obj: any) =>
              searchQuery === ' ' || obj.identifier !== undefined
                ? obj.identifier
                : obj?.toLowerCase()?.includes(searchQuery.toLowerCase())
            )
            ?.map((obj: any, index: number) => (
              <div
                key={index}
                className="data-detail"
                onClick={() => {
                  setFieldValue(
                    name,
                    obj.identifier !== undefined ? obj.identifier : obj
                  );
                  toggleModal();
                  setSelected?.(obj);
                  setIdDetails?.(obj);
                }}
              >
                {obj.logo !== undefined && (
                  <ImageComponent size="m" url={obj.logo} />
                )}
                <div className="type-details">
                  <p className="title">
                    {obj.name !== undefined
                      ? obj.name
                      : obj.fullname !== undefined
                      ? obj.fullname
                      : obj}
                  </p>
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default Select;
