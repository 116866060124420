import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { btnCheck, check, fieldErrorIcon } from '../../../assets';
import { useField, Field } from 'formik';
import { isEmpty } from 'lodash';

interface TextFieldProps {
  bank: string;
  name: string;
  title: string;
  loading: boolean;
  setLoading: (value: boolean) => void;
  submitForm: any;
  setSubmitResponse: any;
  submitResponse: any;
  requestError: any;
  setFieldValue: any;
}

const TextField: FC<TextFieldProps> = ({
  bank,
  title,
  name,
  loading,
  setLoading,
  submitForm,
  requestError,
  setFieldValue,
  submitResponse,
  setSubmitResponse,
}) => {
  const [field, meta] = useField<any>(name);
  const [status, setStatus] = useState<any>();

  const submitAccountNumber = () => {
    setLoading(true);

    if (meta.error === undefined) {
      submitForm();
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (requestError !== null && requestError?.status === false) {
      setTimeout(() => {
        setStatus(null);
      }, 4000);
    }

    if (isEmpty(submitResponse)) {
      setStatus(requestError);
    } else if (!isEmpty(submitResponse)) {
      setStatus(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestError, submitResponse]);

  return (
    <div className="text-field-container">
      <p className="title">{title}</p>
      <Field
        className={
          meta.error !== undefined
            ? 'error-amount-input-container'
            : status !== null && status?.status === false
            ? 'error-amount-input-container'
            : null
        }
        name={field.name}
        type="tel"
        autoComplete="off"
        placeholder="Enter your bank account"
        onChange={(e: any) => {
          setFieldValue('accountNumber', e.target.value);
          setLoading(false);
          setSubmitResponse(null);
        }}
      />

      {bank?.length > 1 && (
        <>
          {!loading && submitResponse === null ? (
            <button
              type="submit"
              className="span-btn"
              onClick={submitAccountNumber}
            >
              <img className="check-icon" src={check} alt="" />
              <span>Submit</span>
            </button>
          ) : loading ? (
            <div className="spinner-container">
              <div className="loading-spinner">{}</div>
            </div>
          ) : !loading && submitResponse !== null ? (
            <span className="check-btn">
              <img src={btnCheck} alt="" />
            </span>
          ) : null}
        </>
      )}

      {meta.error !== undefined ||
      (status !== null && status?.status === false) ? (
        <div className="error-container">
          <img src={fieldErrorIcon} alt="" />
          <p>
            {status !== null && status?.status === false
              ? status?.message
              : meta.error !== undefined
              ? meta.error
              : ''}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default TextField;
