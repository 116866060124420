import { useField, Field } from 'formik';

import type { FC } from 'react';

interface TextFieldProps {
  name: string;
  title: string;
  icon: string;
}

const EmailField: FC<TextFieldProps> = ({ icon, title, ...props }) => {
  const [field] = useField<any>(props);
  const email = localStorage.getItem('email');

  return (
    <div className="text-field">
      <img src={icon} alt="" />
      <Field
        type="email"
        placeholder={title}
        disabled={email !== null && email.length > 1}
        {...props}
        {...field}
        autoComplete="off"
      />
    </div>
  );
};

export default EmailField;
