/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Fragment, useEffect } from 'react';
import { Field, useField } from 'formik';
import ReactGA from 'react-ga';

import type { FC } from 'react';
import type { FieldProps } from '../AmountField/AmountField';
import { getBtcUsdRate, getBtcUsdValue, getBtcValue } from '../../../../utils';

interface AmountInputsProps extends FieldProps {
  switchField: boolean;
  rate: number;
  isDisabled: boolean;
  totalFee: number;
}

const AmountInputs: FC<AmountInputsProps> = ({
  rate,
  crypto,
  usdRate,
  currency,
  rampType,
  isDisabled,
  switchField,
  setFieldValue,
  percentageFee,
  selectedNetwork,
  totalFee,
}) => {
  const [fiatValue] = useField('currencyAmount');
  const [tokenValue] = useField('amount');
  const btcRate = getBtcUsdRate(rate, usdRate);
  const formatNumber = (num: any) => num?.toFixed(3);

  useEffect(() => {
    const getFee = totalFee;

    // const getFee = (value: any, rampTypeFee: number) => {
    //   const pRampTypeFee = rampTypeFee / 100;

    //   const gasFee = fixedGasFee(selectedNetwork);
    //   const fee = pRampTypeFee * value + gasFee;
    //   return fee < 100 ? fee : 100;
    // };

    if (!switchField) {
      const value = fiatValue.value;
      setFieldValue('percentageFee', percentageFee);

      const usdAmount = Number(value) / usdRate;

      const btcValue = getBtcValue(usdAmount, btcRate);

      const assetValue =
        crypto?.toLocaleLowerCase() === 'btc' ? btcValue : usdAmount.toFixed(3);

      setFieldValue(
        'amount',
        value < 0 || Number.isNaN(assetValue) ? '' : assetValue
      );

      const fee = getFee;

      setFieldValue('fee', fee);

      if (rampType === 'on') {
        const toGet = formatNumber(usdAmount) - fee;
        toGet < 0
          ? setFieldValue('toGet', 0)
          : setFieldValue('toGet', formatNumber(toGet));

        ReactGA.event({
          category: 'on-ramp',
          action: 'select amount button click',
          label: 'select_amount',
        });
      } else {
        const toGet = (Number(usdAmount.toFixed(3)) - fee) * usdRate;

        toGet < 0
          ? setFieldValue('toGet', 0)
          : setFieldValue('toGet', toGet.toFixed(2));

        ReactGA.event({
          category: 'off-ramp',
          action: 'select amount button click',
          label: 'select_amount',
        });
      }
    } else {
      const value = tokenValue.value;
      const convertedValue = Number(value);
      const btcValue = getBtcUsdValue(convertedValue, btcRate);

      const assetValue =
        crypto?.toLocaleLowerCase() === 'btc'
          ? btcValue
          : formatNumber(convertedValue);

      setFieldValue('amount', value);
      setFieldValue('percentageFee', percentageFee);

      const fee = getFee;
      setFieldValue('fee', formatNumber(fee));

      const newCurrencyAmount = assetValue * usdRate;
      setFieldValue(
        'currencyAmount',
        value < 0 || Number.isNaN(newCurrencyAmount)
          ? ''
          : formatNumber(newCurrencyAmount)
      );

      if (rampType === 'on') {
        const toGet = assetValue - fee;
        setFieldValue('toGet', formatNumber(toGet));
      } else {
        const toGet = (assetValue - fee) * usdRate;
        setFieldValue('toGet', toGet.toFixed(5));
      }
    }
  }, [
    btcRate,
    crypto,
    fiatValue.value,
    percentageFee,
    rampType,
    rate,
    selectedNetwork,
    setFieldValue,
    switchField,
    tokenValue.value,
    usdRate,
    totalFee,
  ]);

  return (
    <Fragment>
      {!switchField ? (
        <Fragment>
          <div className="input-container top-container">
            <p className={`${rampType === 'off' ? 'off-ramp-token' : ''}`}>
              {currency}
            </p>
            <Field
              {...fiatValue}
              type="number"
              inputMode="decimal"
              placeholder="0.00"
              autoComplete="off"
              disabled={isDisabled}
            />
          </div>
          <div className="input-container top-input-border">
            <p>{crypto}</p>
            <Field
              disabled
              type="text"
              placeholder="0.00"
              name="amount"
              value={
                parseFloat(tokenValue?.value).toLocaleString() !== 'NaN'
                  ? crypto?.toLocaleLowerCase() === 'btc'
                    ? parseFloat(tokenValue?.value).toFixed(8)
                    : parseFloat(tokenValue?.value).toLocaleString()
                  : '0.00'
              }
            />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="input-container top-container">
            <p className={`${rampType === 'off' ? 'off-ramp-token' : ''}`}>
              {crypto}
            </p>
            <Field
              {...tokenValue}
              type="number"
              inputMode="decimal"
              placeholder="0.00"
              autoComplete="off"
              disabled={isDisabled}
            />
          </div>
          <div className="input-container top-input-border">
            <p>{currency}</p>
            <Field
              disabled
              type="text"
              name="currencyAmount"
              placeholder={'0.00'}
              value={
                parseFloat(fiatValue?.value).toLocaleString() !== 'NaN'
                  ? parseFloat(fiatValue?.value).toLocaleString()
                  : '0.00'
              }
            />{' '}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AmountInputs;
