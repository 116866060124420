import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { backIcon } from '../../assets';

export interface BackComponentProps {
  data?: any;
  url?: string;
  title?: string;
  backAction?: () => void;
}

const BackComponent: FC<BackComponentProps> = ({
  data,
  url,
  title,
  backAction,
}) => (
  <div className="back-container">
    {backAction !== undefined ? (
      <div onClick={backAction}>
        <img src={backIcon} alt="" />
      </div>
    ) : (
      <Link to={url !== undefined ? url : ''} state={data}>
        <img src={backIcon} alt="" />
      </Link>
    )}
    <p> {title} </p>
  </div>
);

export default BackComponent;
