import { chevron } from '../../../../assets';
import { Fragment } from 'react';
import type { FC } from 'react';
import ImageComponent from '../../../ImageComponent/ImageComponent';

interface Props {
  currentStage: number;
  fieldPosition: number;
  selectedItem: any;
  shortTitle: string | undefined | null;
  dataModal: boolean;
  toggleDataModal: () => void;
  isDisabled: boolean;
}

const Field: FC<Props> = ({
  currentStage,
  fieldPosition,
  selectedItem,
  shortTitle,
  dataModal,
  isDisabled,
  toggleDataModal,
}) => (
  <Fragment>
    {(currentStage === fieldPosition || currentStage > fieldPosition) && (
      <div
        className={`select-field ${dataModal ? 'active-select-field' : ''}`}
        onClick={isDisabled ? () => null : toggleDataModal}
      >
        <div className="field-title-container">
          {selectedItem === undefined ? (
            ''
          ) : (
            <div className="img-container">
              <ImageComponent
                size={'m'}
                url={
                  selectedItem.logo !== undefined
                    ? selectedItem.logo
                    : undefined
                }
                symbol={
                  selectedItem.logo === undefined
                    ? selectedItem
                    : selectedItem.symbol !== undefined
                    ? selectedItem.symbol
                    : selectedItem.network
                }
              />
            </div>
          )}
          <Fragment>
            {selectedItem !== undefined && typeof selectedItem === 'string' ? (
              <p className="field-title">{selectedItem?.toUpperCase()} </p>
            ) : selectedItem !== undefined &&
              typeof selectedItem !== 'string' ? (
              <p className="field-title">
                {selectedItem?.symbol !== undefined
                  ? selectedItem?.symbol.toUpperCase()
                  : selectedItem?.network.toUpperCase()}{' '}
              </p>
            ) : (
              <p className="place-holder">{shortTitle} </p>
            )}
          </Fragment>
        </div>
        <img
          className={dataModal ? 'rotate' : 'chevron'}
          src={chevron}
          alt="chevron"
        />
      </div>
    )}
  </Fragment>
);

export default Field;
