import type { FC } from 'react';
import type { NetworkProps } from '../../../../../types';
import ImageComponent from '../../../ImageComponent/ImageComponent';

interface SelectFieldModalProps {
  data: any[];
  type: 'network' | 'fiat' | 'token';
  modal: boolean;
  toggle: () => void;
  fieldPosition: number;
  currentStage: number;
  setSelectedItem: (obj: NetworkProps) => void;
  handleNextStage: (fieldPosition: number) => void;
  setCurrentStage: any;
  setFieldValue: (value: string) => void;
  setSelected: any;
  rampType: 'on' | 'off' | undefined;
}

const SelectFieldModal: FC<SelectFieldModalProps> = ({
  data,
  type,
  modal,
  toggle,
  rampType,
  fieldPosition,
  currentStage,
  setSelectedItem,
  handleNextStage,
  setCurrentStage,
  setFieldValue,
  setSelected,
}) => {
  const onRampArray = data?.filter((item) => item.onramp);
  const offRampArray = data?.filter((item) => item.offramp);
  const tokenArray =
    type === 'fiat' ? data : rampType === 'off' ? offRampArray : onRampArray;

  return (
    <div className={`data-modal ${!modal ? 'close-data-modal' : ''}`}>
      {tokenArray?.map((obj: any) => (
        <div
          key={obj.symbol !== undefined ? obj.symbol : obj.network}
          className="data-detail"
          onClick={() => {
            if (obj.comingSoon !== true) {
              setFieldValue(
                obj.symbol !== undefined ? obj.symbol : obj.network
              );
              setSelectedItem(obj);
              toggle();
              handleNextStage(fieldPosition);
              setSelected?.(
                obj.symbol !== undefined ? obj.symbol : obj.network
              );
              fieldPosition > currentStage && setCurrentStage(fieldPosition);
            }
          }}
        >
          <ImageComponent
            size="m"
            url={obj.logo !== undefined ? obj.logo : undefined}
          />
          <div className="type-details">
            <p className="title">
              {type === 'fiat'
                ? obj.fullname
                : obj.name !== undefined
                ? obj.name
                : obj.symbol}
            </p>
            {/* {type === 'network' && (
            <div className="network-list">
              <p className="network">USDT</p>
            </div>
          )} */}
          </div>
        </div>
      ))}
    </div>
  );
};
export default SelectFieldModal;
