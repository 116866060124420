import crypto from 'crypto-js';

interface authMiddlewareProps {
  path: string;
  method: string;
  body: any;
  date: string;
}

export const authMiddleware = ({
  path,
  method,
  body,
  date,
}: authMiddlewareProps) => {
  const SECRET_KEY: any = process.env.REACT_APP_RAMP_SECRET_KEY;
  const API_KEY: any = process.env.REACT_APP_RAMP_API_KEY;

  path = path.includes('?') ? path.split('?')[0] : path;

  const hmac = crypto.algo.HMAC.create(crypto.algo.SHA256, SECRET_KEY);
  hmac.update(date);
  hmac.update(path);
  hmac.update(method);

  if (body !== null) {
    const bodyHmac = crypto
      .SHA256(JSON.stringify(body))
      .toString(crypto.enc.Base64);
    hmac.update(bodyHmac);
  }

  const hash = hmac.finalize();
  const signature = crypto.enc.Base64.stringify(hash);
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${API_KEY}:${signature}`;
};
