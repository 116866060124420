import { Fragment, useEffect, useState } from 'react';
import { useField, Field } from 'formik';

import type { FC, ChangeEvent } from 'react';
import type { WalletAddressProps } from '../WalletAddressField/WalletAddressField';
import { errorIcon, scanIcon } from '../../../../assets';
import FieldStatus from '../../FieldStatus/FieldStatus';
import { handleOnPaste, truncate } from '../../../../utils';

interface Props extends WalletAddressProps {
  status: boolean | undefined;
  name: string;
  network: string;
  address: string;
  isDisabled: boolean;
  setStatus: (v: boolean) => void;
  _VALIDATE_WALLET_ADDRESS: (
    address: string,
    network: string,
    setStatus: (v: boolean) => void
  ) => void;
}

const WalletAddressInput: FC<Props> = ({
  currentStage,
  fieldPosition,
  setFieldValue,
  status,
  name,
  setStatus,
  network,
  isDisabled,
  address,
  _VALIDATE_WALLET_ADDRESS,
}) => {
  const [field, meta] = useField<any>(name);
  const truncatedValue = truncate(field.value, 16);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    if (address !== '' && network !== undefined) {
      _VALIDATE_WALLET_ADDRESS(address, network, setStatus);
    }
  }, [_VALIDATE_WALLET_ADDRESS, address, network, setStatus]);

  return (
    <Fragment>
      {currentStage === fieldPosition && (
        <>
          <div>
            <div className="wallet-address-wrapper">
              <Field
                type="text"
                name={field.name}
                placeholder="Wallet address"
                autoComplete="off"
                className={
                  meta.touched && meta.error !== undefined
                    ? 'error-amount-input-container'
                    : status === false
                    ? 'error-amount-input-container'
                    : status && meta.error === undefined
                    ? 'success-wallet-address'
                    : null
                }
                onBlur={() => {
                  setIsFocused(false);
                }}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(event.target.value);
                }}
                value={isFocused ? field.value : truncatedValue}
                disabled={isDisabled}
              />

              <div className="absolute-icons">
                <span
                  onClick={() => {
                    handleOnPaste(
                      setFieldValue,
                      _VALIDATE_WALLET_ADDRESS,
                      network,
                      setStatus
                    );
                  }}
                >
                  <span>Paste</span>
                </span>

                <div className="img-container web-scan">
                  <img src={scanIcon} alt="scan-icon" />
                </div>
              </div>
            </div>
            {meta.touched && meta.error !== undefined && (
              <FieldStatus
                icon={errorIcon}
                mode={'error'}
                errorMessage={meta.error}
              />
            )}
            {status === false && (
              <FieldStatus
                icon={errorIcon}
                mode={'error'}
                errorMessage={`Please enter a ${network} address`}
              />
            )}
          </div>
        </>
      )}
    </Fragment>
  );
};
export default WalletAddressInput;
