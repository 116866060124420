/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import Layout from '../../components/Layout/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { purpleCaution, offRampCheck, offRampSendBg } from '../../assets';
import CopyComponent from '../../components/CopyComponent/CopyComponent';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { createStructuredSelector } from 'reselect';
import { selectOffRamp } from '../../redux/transaction/transactionSelectors';
import { connect, useDispatch } from 'react-redux';
import type { OffRampData } from '../../../types';
import CountdownTimer from '../../components/Countdown/Countdown';
import Pusher from 'pusher-js';
import QRCode from 'react-qr-code';
import { _VERIFY_SOLANA_PAY } from '../../redux/transaction/transactionActions';

interface Props {
  offRamp: OffRampData;
}

const SendCrypto: FC<Props> = ({ offRamp }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const data = location.state?.data;
  const solanaPayReference = location?.state?.reference;
  const scalexReference = location?.state?.scalexReference;
  const address = location?.state?.address;

  console.log({ stte: location.state });

  const [redirectUrl, setRedirectUrl] = useState<any>();
  const [transactionTitle, setTransactionTitle] = useState<string>('');
  const [transactionMessage, setTransactionMessage] = useState<string>('');

  const [status, setStatus] = useState<
    | 'ramp_failed'
    | 'ramp_successful'
    | 'kyc_failed'
    | 'payment_reversed'
    | 'payment_received'
    | 'payment_delayed'
    | null
  >(null);

  const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
  });

  const channel = pusher.subscribe(
    `scalex_ramp_${offRamp?.reference || scalexReference}`
  ); // channel to subscribe to which is unique to each user

  channel.bind('ramp_successful', function (e: any) {
    setStatus('ramp_successful');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  channel.bind('ramp_failed', function (e: any) {
    setStatus('ramp_failed');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  channel.bind('payment_reversed', function (e: any) {
    setStatus('payment_reversed');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
    setRedirectUrl(e.redirect);
  });

  channel.bind('payment_received', function (e: any) {
    setStatus('payment_received');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
  });

  channel.bind('payment_delayed', function (e: any) {
    setStatus('payment_delayed');
    setTransactionMessage(e.message);
    setTransactionTitle(e.title);
  });

  useEffect(() => {
    const payload = {
      address: offRamp?.wallet_to_fund?.address || address,
      from_amount: offRamp?.from_amount,
      to_amount: offRamp?.to_amount,
      reference: offRamp.reference,
    };
    window.parent.postMessage(payload, '*');
  }, [
    offRamp?.from_amount,
    offRamp.reference,
    offRamp?.to_amount,
    offRamp?.wallet_to_fund?.address,
    address,
  ]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(_VERIFY_SOLANA_PAY(solanaPayReference));
    }, 5000);
  }, [dispatch, solanaPayReference]);

  useEffect(() => {
    (status === 'ramp_failed' ||
      status === 'ramp_successful' ||
      status === 'payment_reversed' ||
      status === 'payment_delayed' ||
      status === 'payment_received') &&
      navigate('/transaction-status', {
        state: {
          data,
          status,
          redirectUrl,
          rampType: 'off',
          transactionTitle,
          transactionMessage,
          reference: offRamp.reference || scalexReference,
        },
      });
  }, [
    data,
    navigate,
    offRamp.reference,
    status,
    redirectUrl,
    transactionTitle,
    transactionMessage,
    scalexReference,
  ]);

  return (
    <ScrollToTop>
      <Layout>
        <div className="transfer-info send-crypto">
          <img className="top-page-bg" src={offRampSendBg} alt="" />

          <div className="page-title">
            <p>Send</p>
            <h5>
              {parseFloat(data?.amount)
                .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                .toLocaleString() || '--'}{' '}
              <span>{data?.token?.toUpperCase() || '--'}</span>
              <CopyComponent text={data?.amount || '--'} icon={offRampCheck} />
            </h5>
          </div>

          <div className="wallet-container">
            <p className="transfer-into-title">Send to</p>
            <div className="address-container">
              <div className="inner">
                <span className="wallet-flex">
                  <p className="inner-title">Wallet address</p>
                  <h6 className="wallet-address">
                    {offRamp?.wallet_to_fund?.address || address || '--'}
                  </h6>
                </span>
                {(offRamp?.wallet_to_fund?.address || address) && (
                  <CopyComponent
                    text={offRamp?.wallet_to_fund?.address || address || '--'}
                    icon={offRampCheck}
                    position="lower"
                  />
                )}
              </div>

              <div className="flex-details">
                <div className="inner-flex">
                  <div className="">
                    <span className="wallet-flex">
                      <p className="inner-title">Amount to receive</p>
                      <div className="token-network-flex">
                        <h6>
                          {Number(data?.toGet)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || ''}
                        </h6>
                        <h6>{data?.currency || '--'}</h6>
                      </div>
                    </span>
                  </div>
                  <div className="">
                    <span className="wallet-flex">
                      <p className="inner-title">Token and Network</p>
                      <div className="token-network-flex">
                        <h6>{data?.token || '--'}</h6>
                        <span>{data?.network.toUpperCase() || '--'}</span>
                      </div>
                    </span>
                  </div>
                </div>

                {(offRamp?.wallet_to_fund?.address || address) && (
                  <div className="qr-container">
                    <QRCode
                      size={256}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      value={
                        offRamp?.wallet_to_fund?.address || address || '--'
                      }
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <CountdownTimer />

          <div className="info-frame">
            <img src={purpleCaution} alt="" />
            <p>
              Depositing any other crypto asset besides {data?.token} to this
              address may result in total loss of your funds. Scalex will not be
              responsible for any funds lost due to the deposit of a different
              token.
            </p>
          </div>

          <div className="partner-btn-container">
            {offRamp?.meta?.paymentButton && (
              <PrimaryButton
                type={'button'}
                validForm={null}
                icon={offRamp?.meta?.paymentButton?.logo}
                title={offRamp?.meta?.paymentButton?.text}
                style={{
                  margin: 0,
                  background: offRamp?.meta?.paymentButton?.backgroundColor,
                }}
                onClick={() => {
                  window.location.href = offRamp?.meta?.paymentButton?.url
                    ? offRamp?.meta?.paymentButton?.url
                    : '';
                }}
              />
            )}
            <PrimaryButton
              validForm={null}
              type={'button'}
              title={'I’ve sent the token'}
              onClick={() => {
                navigate('/transaction-status', {
                  state: {
                    data,
                    reference: offRamp.reference,
                    rampType: 'off',
                  },
                });
                ReactGA.event({
                  category: 'sent the token',
                  action: 'have sent the token button click',
                  label: 'sent_token',
                });
              }}
              style={{ background: ' #F3F5F7', color: '#191C1F', margin: 0 }}
            />
          </div>
        </div>
      </Layout>
    </ScrollToTop>
  );
};

const mapStateToProps = createStructuredSelector({
  offRamp: selectOffRamp,
});

export default connect(mapStateToProps, null)(SendCrypto);
