import { darkLogo } from '../../../assets';
import type { FC } from 'react';
import BackComponent from '../../BackComponent/BackComponent';
import type { BackComponentProps } from '../../BackComponent/BackComponent';
import { Link } from 'react-router-dom';

interface OnrampTopBarProps extends BackComponentProps {
  icon: string;
  rampType: 'on' | 'off';
  receivingBank?: boolean;
}

const RampTopBar: FC<OnrampTopBarProps> = ({
  icon,
  rampType,
  receivingBank,
  data,
  url,
  title,
}) => {
  const email: string | any = localStorage.getItem('email');
  return (
    <div className="onramp-top-bar">
      <div className="top-bar">
        <Link to="/">
          <img src={darkLogo} alt="" />
        </Link>
        <div className="top-bar-user-details">
          {email?.length < 1 ? (
            <p className="onramp-active">--</p>
          ) : (
            <p className="user-id">{email}</p>
          )}
          <p className="onramp-active">
            <span>
              <img src={icon} alt="active-icon" />
            </span>
            {rampType === 'on' ? 'BUY CRYPTO' : 'SELL CRYPTO'}
          </p>
        </div>
      </div>
      {receivingBank === undefined || !receivingBank ? (
        <h4 className="top-bar-title">{`To ${
          rampType === 'on' ? 'buy' : 'sell'
        } crypto, follow these steps`}</h4>
      ) : (
        <BackComponent data={data} url={url} title={title} />
      )}{' '}
    </div>
  );
};

export default RampTopBar;
