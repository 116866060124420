/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { ngn } from '../assets';

export const Tokens = [
  {
    name: 'USDT',
    identifier: 'usdc_tron',
    symbol: 'USDT',
    networks: [
      {
        symbol: 'bsc',
        identifier: 'bsc',
        name: 'BSC (Binance Smart Chain)',
        networks: ['usdt', 'usdc', 'busd'],
      },
      {
        symbol: 'polygon',
        identifier: 'polygon',
        name: 'Polygon',
        networks: ['sdt', 'usdc', 'busd', 'matic', 'dai'],
      },
      {
        symbol: 'tron',
        identifier: 'trc',
        name: 'TRON',
        networks: ['usdt', 'usdc'],
      },
    ],
  },
];

export const fixedGasFee = (network: string) => {
  if (network?.toLowerCase() === 'tron' || network?.toLowerCase() === 'btc') {
    return 1;
  } else return 0.25;
};

export const GenderList = [
  {
    name: 'Male',
    identifier: 'M',
  },
  {
    name: 'Female',
    identifier: 'F',
  },
];

export const Countries = [
  {
    name: 'Nigeria',
    identifier: 'NGG',
    code: '+234',
    logo: ngn,
  },
];

export const getParamsToken = (tokenArray: any, paramsToken: string | null) => {
  if (paramsToken !== null) {
    const tokenObject = tokenArray.find(
      (obj: any) => obj.symbol.toLowerCase() === paramsToken.toLowerCase()
    );
    return tokenObject;
  }
};

export const getParamsNetwork = (
  tokenArray: any,
  paramsToken: string | null,
  paramsNetwork: string | null
) => {
  if (paramsToken !== null && paramsNetwork !== null) {
    const tokenObject = tokenArray.find(
      (obj: any) => obj.symbol.toLowerCase() === paramsToken.toLowerCase()
    );

    const networkObject = tokenObject?.networks?.find(
      (obj: any) => obj.network.toLowerCase() === paramsNetwork.toLowerCase()
    );
    return networkObject;
  }
};

export const getRate = (rates: any, token: string, network: string) => {
  if (token !== undefined && network !== undefined) {
    const rateObj = rates?.find(
      (obj: any) =>
        obj.token.toLowerCase() === token?.toLowerCase() &&
        obj.network.toLowerCase() === network?.toLowerCase()
    );
    return rateObj?.rate_in_ngn;
  }
};

export const handleOnPaste = (
  setFieldValue: (value: string) => void,
  _VALIDATE_WALLET_ADDRESS: (
    address: string,
    network: string,
    setStatus: (v: boolean) => void
  ) => void,
  network: string,
  setStatus: (v: boolean) => void
) => {
  navigator?.clipboard
    ?.readText()
    .then((text) => {
      setFieldValue(text);
      _VALIDATE_WALLET_ADDRESS(text, network, setStatus);
    })
    .catch((err) => {
      console.error('Failed to read clipboard contents: ', err);
    });
};

export const calculateCryptoAmount = (toGet: any, rate: number) =>
  (toGet?.replace(/,/g, '') / rate).toFixed(2);

export const getNetworks = (supportedTokens: any, selectedToken: string) => {
  if (supportedTokens !== undefined && selectedToken !== undefined) {
    const networks = supportedTokens?.find(
      (obj: any) => obj.symbol.toLowerCase() === selectedToken.toLowerCase()
    );
    return networks?.networks;
  }
};

export const truncate = (text: string, limit: number) => {
  if (text?.length <= limit) {
    return text;
  }

  if (window.innerWidth < 539) {
    return text?.slice(0, limit) + '...';
  } else {
    return text;
  }
};

export const getBtcUsdRate = (fiatRate: number, usdRate: number) => {
  const value = fiatRate / usdRate;
  return value;
};

export const getBtcUsdValue = (amount: number, btcRate: number) => {
  const value = amount * btcRate;
  return value;
};

export const getBtcValue = (amount: number, btcRate: number) => {
  const value = amount / btcRate;
  return value;
};

export const testRecipient = {
  payer: 'mvines9iiHiQTysrwkJjGf2gb9Ex9jXJX8ns3qwf2kN',
  reference: 'FwewVm8u6tFPGewAyHmWAqad9hmF7mvqxK4mJ7iNqqGC',
  amount: 1,
  recipient: '8ancT6gZyjsmqEXcshEy2EnLVREwN757LxkY5VmNHbkN',
  memo: 'joelisagoatmeeehhhh',
};

export const getOperatingSystem = (window: any) => {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS';
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS';
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS';
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS';
  }

  return operatingSystem;
};
