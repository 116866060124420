/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable autofix/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ReactGA from 'react-ga';

import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import Layout from '../../components/Layout/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import InputField from '../../components/Fields/InputField/InputField';
import Select from '../../components/Fields/Select/Select';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { errorIcon, lock, logo, logoBg } from '../../assets';
import PageLogo from '../../components/PageLogo/PageLogo';
import {
  _KYC_TIER_THREE,
  _GET_COUNTRY_WITH_STATE,
  _GET_LOCAL_GOVERNMENT,
  _GET_SAVED_BANK,
} from '../../redux/transaction/transactionActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import FieldStatus from '../../components/Fields/FieldStatus/FieldStatus';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  selectCountriesWithState,
  selectLocalGov,
  selectOnAndOffRampError,
} from '../../redux/transaction/transactionSelectors';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import type { ICountryWithState, ILocalGovernment } from '../../../types';
import FileUpload from '../../components/Fields/FileUpload/FileUpload';

interface Props {
  _KYC_TIER_THREE: (
    data: any,
    email: string,
    rampType: string,
    state: string,
    address: string,
    postalCode: string,
    localGov: string,
    proofOfAddress: any,
    navigate: any,
    setChecking: (v: boolean) => void,
    setErrorMessage: (v: object) => void,
    setKycData: (v: object) => void,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) => void;
  _GET_COUNTRY_WITH_STATE: (email: string) => void;
  _GET_LOCAL_GOVERNMENT: (email: string, state: string) => void;
  onAndOffRampError: any;
  countryWithState: ICountryWithState;
  localGov: ILocalGovernment;
}
const Tier3Verification: FC<Props> = ({
  _KYC_TIER_THREE,
  _GET_COUNTRY_WITH_STATE,
  _GET_LOCAL_GOVERNMENT,
  onAndOffRampError,
  countryWithState,
  localGov,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const data = location.state?.data;
  const email = location.state?.email;
  const rampType = location.state?.rampType;
  const [kycData, setKycData] = useState<any>(null);
  const [checking, setChecking] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [activeButton, setActiveButton] = useState<boolean>(true);
  const [selectedState, setSelectedState] = useState<any>(null);
  const savedBanks = useSelector(
    (state: any) => state.transaction.getSavedBanks
  );

  useEffect(() => {
    _GET_COUNTRY_WITH_STATE(email);
    selectedState !== null && _GET_LOCAL_GOVERNMENT(email, selectedState);
  }, [_GET_COUNTRY_WITH_STATE, _GET_LOCAL_GOVERNMENT, email, selectedState]);

  useEffect(() => {
    dispatch(_GET_SAVED_BANK(email));
  }, [dispatch, email]);

  return (
    <Layout>
      <ScrollToTop>
        <div className="basic-kyc">
          <TopPageTransactionInfo
            title={'PAYING'}
            rampType={rampType}
            amount={
              rampType === 'on'
                ? parseFloat(data?.currencyAmount).toLocaleString()
                : parseFloat(data?.amount)
                    .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                    .toLocaleString()
            }
            token={rampType === 'on' ? data?.currency : data?.token}
          />

          {(!checking || onAndOffRampError !== null) && (
            <>
              <div className="page-title">
                <h5 className="title">Just one more step, please</h5>
                <p className="description">
                  We need your details to verify your identity. This is a
                  one-time request.
                </p>

                <div className="privacy-container">
                  <img src={lock} alt="" />
                  <p>
                    Your privacy is our priority! Rest assured that your
                    information will never be used for any purpose other than
                    what we have explicitly stated. Your data is safe with us.
                  </p>
                </div>
              </div>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={{
                  state: kycData !== null ? kycData.state : '',
                  address: kycData !== null ? kycData.address : '',
                  postalCode: kycData !== null ? kycData.postal_code : '',
                  localGovt: kycData !== null ? kycData.lga : '',
                  proofOfAddress: null,
                }}
                validationSchema={Yup.object({
                  state: Yup.string().required('This is a required field'),
                  address: Yup.string().required('This is a required field'),
                  postalCode: Yup.number(),
                  localGovt: Yup.string().required('This is a required field'),
                  proofOfAddress: Yup.mixed()
                    .required('Proof of address is required')
                    .test(
                      'fileType',
                      'Invalid file format. Only PNG, JPG, JPEG and PDF are allowed.',
                      (value: any) => {
                        if (!value) return false;

                        const validFormats = [
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                          'application/pdf',
                        ];
                        return validFormats.includes(value.type);
                      }
                    ),
                })}
                onSubmit={(values, { setErrors }) => {
                  const errors = {};
                  const {
                    state,
                    address,
                    postalCode,
                    localGovt,
                    proofOfAddress,
                  } = values;

                  if (
                    Object.entries(errors).length === 0 &&
                    errors.constructor === Object
                  ) {
                    setChecking(true);
                    setActiveButton(false);

                    _KYC_TIER_THREE(
                      data,
                      email,
                      rampType,
                      address,
                      postalCode,
                      state,
                      localGovt,
                      proofOfAddress,
                      navigate,
                      setChecking,
                      setErrorMessage,
                      setKycData,
                      setActiveButton,
                      savedBanks
                    );

                    ReactGA.event({
                      category: 'proof of address verification',
                      action: 'proof of address verification button click',
                      label: 'proof_of_address',
                    });
                  } else {
                    setErrors(errors);
                  }
                }}
              >
                {({ handleSubmit, setFieldValue, isValid }) => (
                  <Form className="form-control" onSubmit={handleSubmit}>
                    <p className="kyc-title">ADDRESS INFORMATION</p>

                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        gap: 5,
                      }}
                    >
                      <Select
                        title={'State'}
                        data={
                          countryWithState?.states !== undefined
                            ? countryWithState?.states
                            : []
                        }
                        setFieldValue={setFieldValue}
                        name={'state'}
                        reInitValue={kycData?.state}
                        setIdDetails={setSelectedState}
                      />
                      <Select
                        title={
                          window.innerWidth > 600
                            ? 'Local government'
                            : 'Local Govt'
                        }
                        data={
                          localGov?.lgas !== undefined ? localGov?.lgas : []
                        }
                        setFieldValue={setFieldValue}
                        name={'localGovt'}
                        reInitValue={kycData?.lga}
                      />
                    </div>

                    <InputField name="address" placeHolder="Address" />
                    <InputField
                      name="postalCode"
                      placeHolder="Postal code (Optional)"
                    />

                    <FileUpload
                      text={'Proof of address'}
                      name={'proofOfAddress'}
                      setFieldValue={(value: any) => {
                        setFieldValue('proofOfAddress', value);
                      }}
                    />

                    {(errorMessage?.length > 1 ||
                      (!isEmpty(errorMessage) &&
                        errorMessage?.verified === false) ||
                      onAndOffRampError !== null) && (
                      <FieldStatus
                        icon={errorIcon}
                        mode={'error'}
                        errorMessage={
                          errorMessage?.result_text !== undefined
                            ? errorMessage?.result_text
                            : onAndOffRampError !== null
                            ? onAndOffRampError
                            : errorMessage
                        }
                      />
                    )}
                    <PrimaryButton
                      validForm={isValid}
                      type={'submit'}
                      title={'Continue'}
                      activeButton={activeButton}
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}

          {checking && onAndOffRampError === null && (
            <div className="top-welcome-page-content">
              <PageLogo iconBg={logoBg} icon={logo} />
              <h1 className="welcome-message">
                Thank you! Your details have been taken
              </h1>

              <LoadingComponent />
            </div>
          )}
        </div>
      </ScrollToTop>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  onAndOffRampError: selectOnAndOffRampError,
  countryWithState: selectCountriesWithState,
  localGov: selectLocalGov,
});

const mapDispatchToProps = (dispatch: any) => ({
  _KYC_TIER_THREE: (
    data: any,
    email: string,
    rampType: string,
    state: string,
    address: string,
    postalCode: string,
    localGovt: string,
    proofOfAddress: any,
    navigate: any,
    setChecking: (v: boolean) => void,
    setErrorMessage: (v: object) => void,
    setKycData: (v: object) => void,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) =>
    dispatch(
      _KYC_TIER_THREE(
        data,
        email,
        rampType,
        state,
        address,
        postalCode,
        localGovt,
        proofOfAddress,
        navigate,
        setChecking,
        setErrorMessage,
        setKycData,
        setActiveButton,
        savedBanks
      )
    ),
  _GET_COUNTRY_WITH_STATE: (email: string) =>
    dispatch(_GET_COUNTRY_WITH_STATE(email)),
  _GET_LOCAL_GOVERNMENT: (email: string, state: string) =>
    dispatch(_GET_LOCAL_GOVERNMENT(email, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tier3Verification);
