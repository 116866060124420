import type { FC } from 'react';
import { scalexLogoIcon, scalexLogoText } from '../../assets';

const Footer: FC = () => (
  <div className="page-footer">
    <p className="font-text">
      Need help?{' '}
      <a
        href="mailto:support@scalex.africa"
        target="blank"
        className="footer-link"
      >
        Contact us
      </a>
    </p>

    <p className="footer-info">
      bridged by
      <span>
        <img src={scalexLogoIcon} alt="logo-icon" />
        <img src={scalexLogoText} alt="logo-text" />
      </span>
    </p>
  </div>
);

export default Footer;
