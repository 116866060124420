import React from 'react';
import { ada, cedies, eth, kes, ngn, usda, usdc } from '../../assets';

interface ImageProp {
  symbol?: string;
  size: 'b' | 's' | 'm';
  url?: string;
}

function ImageComponent({ symbol, size, url }: ImageProp) {
  return (
    <>
      {symbol?.toLowerCase() === 'sol' || symbol?.toLowerCase() === 'solana' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={
            url !== undefined && url !== null
              ? url
              : 'https://res.cloudinary.com/https-scalex-africa/image/upload/v1695845257/DigitalAssets/solana-sol-logo_tjtndy.svg'
          }
          alt=""
        />
      ) : symbol?.toLowerCase() === 'eth' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={url !== undefined ? url : eth}
          alt=""
        />
      ) : symbol?.toLowerCase() === 'usdt' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={
            url !== undefined
              ? url
              : 'https://res.cloudinary.com/https-scalex-africa/image/upload/v1685543996/DigitalAssets/checkout%20logos/tether-usdt-logo_a5szld.svg'
          }
          alt=""
        />
      ) : symbol?.toLowerCase() === 'busd' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={
            url !== undefined
              ? url
              : 'https://res.cloudinary.com/https-scalex-africa/image/upload/v1685543996/DigitalAssets/checkout%20logos/binance-usd-busd-logo_rq2ayz.svg'
          }
          alt=""
        />
      ) : symbol?.toLowerCase() === 'bnb' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={
            'https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1644979850'
          }
          alt=""
        />
      ) : symbol?.toLowerCase() === 'usdc' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={url !== undefined ? url : usdc}
          alt=""
        />
      ) : symbol?.toLowerCase() === 'ngn' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={url !== undefined ? url : ngn}
          alt=""
        />
      ) : symbol?.toLowerCase() === 'kes' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={url !== undefined ? url : kes}
          alt=""
        />
      ) : symbol?.toLowerCase() === 'ghc' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={url !== undefined ? url : cedies}
          alt=""
        />
      ) : symbol?.toLowerCase() === 'tron' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={
            url !== undefined
              ? url
              : 'https://res.cloudinary.com/https-scalex-africa/image/upload/v1685543996/DigitalAssets/checkout%20logos/tron-trx-logo_jzzwqh.svg'
          }
          alt=""
        />
      ) : symbol?.toLowerCase() === 'polygon' ||
        symbol?.toLowerCase() === 'matic' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={
            url !== undefined
              ? url
              : 'https://res.cloudinary.com/https-scalex-africa/image/upload/v1688934488/DigitalAssets/polygon-matic-logo_ttg1lb.svg'
          }
          alt=""
        />
      ) : symbol?.toLowerCase() === 'bep20' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={
            url !== undefined
              ? url
              : 'https://res.cloudinary.com/https-scalex-africa/image/upload/v1685545287/DigitalAssets/checkout%20logos/bnb-bnb-logo_talg64.svg'
          }
          alt=""
        />
      ) : symbol?.toLowerCase() === 'btc' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={
            url !== undefined
              ? url
              : 'https://res.cloudinary.com/https-scalex-africa/image/upload/v1706655982/DigitalAssets/checkout%20logos/bitcoin-btc-logo_sqkf9u.svg'
          }
          alt=""
        />
      ) : symbol?.toLowerCase() === 'usda' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={url !== undefined ? url : usda}
          alt=""
        />
      ) : symbol?.toLowerCase() === 'ada' ? (
        <img
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          src={url !== undefined ? url : ada}
          alt=""
        />
      ) : symbol === undefined ? (
        <img
          src={url}
          className={
            size === 'b'
              ? 'token-icon'
              : size === 'm'
              ? 'token-medium-icon'
              : 'small-token-icon'
          }
          alt=""
        />
      ) : null}
    </>
  );
}

export default ImageComponent;
