/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useState } from 'react';
import type { FC } from 'react';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import RampConfirmTransaction from '../../components/ConfirmRampTransaction/ConfirmRampTransaction';
import PageInfo from '../../components/PageInfo/PageInfo';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import { calculateCryptoAmount } from '../../utils';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import { _OFF_RAMP } from '../../redux/transaction/transactionActions';

const ConfirmTransaction: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const data = location?.state?.data || '';
  const backUrl: string = location?.state?.backUrl;
  const rampType: 'on' | 'off' = location?.state?.rampType;
  const email: any = localStorage.getItem('email');
  const [activeButton, setActiveButton] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const existingAddress: any = localStorage.getItem('address');
  const USDT_BEP_20_WALLET = process.env.REACT_APP_USDT_BEP_20_WALLET;
  const USDT_TRC_20_WALLET = process.env.REACT_APP_USDT_TRC_20_WALLET;
  const { network, gasFee } = data;

  const setWalletAddress = (address: any, bepAddress: any, trcAddress: any) => {
    if (address !== null && address.length > 10) {
      return address;
    } else if (network?.toLowerCase() === 'bep20') {
      return bepAddress;
    } else if (network?.toLowerCase() === 'tron') {
      return trcAddress;
    } else {
      return '';
    }
  };

  const navigateBack = (): void => {
    const state =
      rampType === 'on' ? { data, stage: 5, loading: false } : { data };
    navigate(backUrl, { state });
  };

  // const gasFee: number = fixedGasFee(data?.network);

  const cryptoToPay = calculateCryptoAmount(data?.toGet, data?.rate);

  const handleRoute = () => {
    setLoading(true);
    if (rampType === 'on') {
      navigate('/verify-email', { state: { data, rampType } });
    } else {
      setActiveButton(false);
      dispatch(
        _OFF_RAMP(
          data,
          setWalletAddress(
            existingAddress,
            USDT_BEP_20_WALLET,
            USDT_TRC_20_WALLET
          ),
          email,
          navigate,
          setActiveButton
        )
      );

      ReactGA.event({
        category: 'off-ramp',
        action: 'confirm transaction button',
        label: 'confirm_transaction',
      });
    }
  };

  return (
    <ScrollToTop>
      <Layout>
        {loading && rampType === 'on' && (
          <TopPageTransactionInfo
            title={'PAYING'}
            rampType={rampType}
            amount={rampType === 'on' ? data?.toGet : cryptoToPay}
            token={rampType === 'on' ? data?.currency : data?.token}
          />
        )}

        {!loading || rampType === 'off' ? (
          <RampConfirmTransaction
            navigateBack={navigateBack}
            data={data ?? ''}
            gasFee={gasFee ?? ''}
            backUrl={backUrl ?? ''}
            rampType={rampType}
            handleRoute={handleRoute}
            activeButton={activeButton}
            email={email}
          />
        ) : loading || rampType === 'on' ? (
          <div className="verify-email-content">
            <PageInfo
              text="Ramp is checking if your identity is validated..."
              subTitle="Kindly wait for a few seconds"
            />
          </div>
        ) : (
          ''
        )}
      </Layout>
    </ScrollToTop>
  );
};

export default ConfirmTransaction;
