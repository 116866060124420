/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';
import Layout from '../../components/Layout/Layout';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import RampTopBar from '../../components/OnRampComponents/RampTopBar/RampTopBar';
import { offRampActiveIcon } from '../../assets';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '../../components/Fields/TextField/TextField';
import { createStructuredSelector } from 'reselect';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import SelectBank from '../../components/Fields/SelectBank/SelectBank';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import {
  _GET_BANK_LIST,
  _RESOLVE_BANK,
} from '../../redux/transaction/transactionActions';
import { connect } from 'react-redux';
import {
  selectBankList,
  selectResolveBank,
  selectResolveBankError,
  selectTransactionLoading,
} from '../../redux/transaction/transactionSelectors';
import type { IBankData, IResolveBankData } from '../../../types';
import { isEmpty } from 'lodash';

interface Props {
  _GET_BANK_LIST: () => void;
  _RESOLVE_BANK: (
    bankCode: string,
    accountNumber: string,
    setLoading: (v: boolean) => void
  ) => void;
  bankList: IBankData[];
  error: any;
  resolveBank: IResolveBankData;
  transactionLoad: boolean;
}

const ReceivingBank: FC<Props> = ({
  _GET_BANK_LIST,
  _RESOLVE_BANK,
  bankList,
  error,
  resolveBank,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const transactionData = location.state;
  const address = localStorage.getItem('address');
  const [submitResponse, setSubmitResponse] = useState<any>(null);
  const email = localStorage.getItem('email');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!isEmpty(resolveBank)) {
      setSubmitResponse(resolveBank);
    }
  }, [resolveBank, error]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    _GET_BANK_LIST();
  }, [_GET_BANK_LIST]);

  return (
    <Layout>
      <ScrollToTop>
        <div className="ramp-main">
          <RampTopBar
            icon={offRampActiveIcon}
            rampType="off"
            receivingBank={true}
            url={
              email?.length === 0 || email === null
                ? '/off-ramp'
                : '/saved-banks'
            }
            data={{ data: transactionData, stage: 4, loading: false }}
            title={'Add receiving bank account'}
          />

          <Formik
            enableReinitialize
            initialValues={{
              accountNumber:
                submitResponse !== null ? submitResponse.account_number : '',
              bankCode: submitResponse !== null ? submitResponse.bank_code : '',
              bank: submitResponse !== null ? submitResponse.bank_name : '',
            }}
            validationSchema={Yup.object({
              accountNumber: Yup.string()
                .min(10, 'Account number is invalid. Try again')
                .max(10, 'Account number is invalid. Try again')
                .required('This is a required field'),
              bank: Yup.string().required('This is a required field'),
            })}
            onSubmit={(values, { setErrors }) => {
              const errors = {};

              if (
                Object.entries(errors).length === 0 &&
                errors.constructor === Object
              ) {
                const { accountNumber, bankCode } = values;

                _RESOLVE_BANK(bankCode, accountNumber, setLoading);
                ReactGA.event({
                  category: 'resolve bank',
                  action: 'resolve bank button click',
                  label: 'receiving_bank',
                });
                setLoading(true);
              } else {
                setErrors(errors);
                setLoading(false);
              }
            }}
          >
            {({ handleSubmit, setFieldValue, errors, values, submitForm }) => (
              <Form className="form-control" onSubmit={handleSubmit}>
                <SelectBank
                  setFieldValue={setFieldValue}
                  title={'Select bank'}
                  bankList={bankList}
                  requestError={error}
                  reIntValue={submitResponse?.bank_name}
                />

                <TextField
                  name="accountNumber"
                  title="Enter your bank account"
                  submitForm={submitForm}
                  bank={values.bank}
                  loading={loading}
                  setLoading={setLoading}
                  setFieldValue={setFieldValue}
                  submitResponse={submitResponse}
                  setSubmitResponse={setSubmitResponse}
                  requestError={error}
                />

                <PrimaryButton
                  validForm={
                    Boolean(submitResponse) &&
                    errors.accountNumber === undefined &&
                    errors.bank === undefined &&
                    errors.bankCode === undefined
                  }
                  type={'button'}
                  title={'Continue'}
                  onClick={() => {
                    navigate('/confirm-account', {
                      state: {
                        data: {
                          ...transactionData,
                          ...submitResponse,
                          ...values,
                          address,
                        },
                      },
                    });
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </ScrollToTop>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  bankList: selectBankList,
  error: selectResolveBankError,
  resolveBank: selectResolveBank,
  transactionLoad: selectTransactionLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  _GET_BANK_LIST: () => dispatch(_GET_BANK_LIST()),

  _RESOLVE_BANK: (
    bankCode: string,
    accountNumber: string,
    setLoading: (v: boolean) => void
  ) => dispatch(_RESOLVE_BANK(bankCode, accountNumber, setLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingBank);
