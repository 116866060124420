import BigNumber from 'bignumber.js';

export interface ISolanaPayButtonProps {
  recipient: string;
  amount: number;
  name: string;
}

export interface ISerializedTxRes {
  transaction: string;
  message?: string;
}

export enum SolanaClusters {
  Devnet = 'devnet',
  Testnet = 'testnet',
  Mainnet = 'mainnet-beta',
}

export enum SolanaCommitments {
  Confirmed = 'confirmed',
  Finalized = 'finalized',
}

export const SolanaConfig: {
  cluster: SolanaClusters;
  commitment: SolanaCommitments;
} = {
  cluster: SolanaClusters.Mainnet,
  commitment: SolanaCommitments.Confirmed,
};

export interface MakeTransactionInputData {
  account: string;
}

export const TEN = new BigNumber(10);

export enum tokens {
  USDC = 'USDC',
  USDT = 'USDT',
}

export const smartContracts: {
  [key in tokens]: string;
} = {
  USDC: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  USDT: '',
};
