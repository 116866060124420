import { useEffect, useRef, useState } from 'react';
import type { NetworkProps } from '../../../../../types';
import type { FC, SetStateAction, Dispatch } from 'react';
import SelectFieldModal from '../SelectFieldModal/SelectFieldModal';
import FieldPosition from '../../FieldPosition/FieldPosition';
import Field from '../Field/Field';
import useEscapeKey from '../../../../hooks/useEscapeKey';
import useOutsideClick from '../../../../hooks/useOutsideClick';

export interface FieldProps {
  data?: any;
  name: string;
  isDisabled: boolean;
  title: string;
  active?: boolean;
  fieldPosition: number;
  currentStage: number;
  rampType?: 'on' | 'off';
  reInitData: any;
  type: 'network' | 'fiat' | 'token';
  shortTitle?: string | undefined | null;
  setFieldValue: (value: string) => void;
  handleNextStage: (fieldPosition: number) => void;
  setCurrentStage: Dispatch<SetStateAction<number>>;
  setSelected?: Dispatch<SetStateAction<any>>;
  setTokenDetails?: Dispatch<SetStateAction<any>>;
}

const SelectField: FC<FieldProps> = ({
  isDisabled,
  data,
  title,
  type,
  rampType,
  shortTitle,
  reInitData,
  fieldPosition,
  currentStage,
  setFieldValue,
  handleNextStage,
  setCurrentStage,
  setSelected,
  setTokenDetails,
}) => {
  const ref = useRef(null);
  const [dataModal, setDataModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<NetworkProps>(reInitData);

  const toggleDataModal = () => {
    setDataModal(!dataModal);
  };

  const handleClose = () => {
    setDataModal(false);
  };

  useEffect(() => {
    setTokenDetails?.(selectedItem);
  }, [selectedItem, setTokenDetails]);

  useEscapeKey(handleClose);
  useOutsideClick(handleClose, ref);

  return (
    <div className="select-field-container" ref={ref}>
      <FieldPosition
        rampType={rampType}
        fieldPosition={fieldPosition}
        currentStage={currentStage}
      />

      <div
        className={`field-container ${
          currentStage === fieldPosition ? 'no-border' : ''
        } `}
      >
        <p
          className={`title ${
            currentStage < fieldPosition ? 'in-active-field' : ''
          }`}
        >
          {title}
        </p>
        <Field
          isDisabled={isDisabled}
          currentStage={currentStage}
          fieldPosition={fieldPosition}
          selectedItem={selectedItem}
          dataModal={dataModal}
          shortTitle={shortTitle}
          toggleDataModal={toggleDataModal}
        />

        <SelectFieldModal
          type={type}
          data={data}
          rampType={rampType}
          modal={dataModal}
          toggle={toggleDataModal}
          currentStage={currentStage}
          fieldPosition={fieldPosition}
          setFieldValue={setFieldValue}
          setSelectedItem={setSelectedItem}
          handleNextStage={handleNextStage}
          setCurrentStage={setCurrentStage}
          setSelected={setSelected}
        />
      </div>
    </div>
  );
};

export default SelectField;
