import { Container } from 'reactstrap';
import { useMemo, type FC, type ReactNode } from 'react';
// import { scalexLogoIcon, scalexLogoText } from '../../assets';
import ScrollToTop from '../ScrollToTopComponent/ScrollToTop';
import Footer from '../Footer/Footer';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { SolanaConfig } from '../interface';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const wallets = useMemo(() => [new PhantomWalletAdapter()], []);

  const network = clusterApiUrl(SolanaConfig.cluster);

  return (
    <ConnectionProvider endpoint={network}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <ScrollToTop>
            <div className="custom-layout">
              <Container>
                <div className="children-container">{children}</div>

                <Footer />
              </Container>
            </div>
          </ScrollToTop>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default Layout;
