import {
  activeLine,
  inActiveLongLine,
  longActiveLine,
  offRampActiveLine,
  unActiveLine,
} from '../../../assets';
import type { FC } from 'react';

interface FieldPositionProps {
  fieldPosition: number;
  currentStage: number;
  rampType?: 'on' | 'off';
  lastStage?: boolean;
}

const FieldPosition: FC<FieldPositionProps> = ({
  currentStage,
  fieldPosition,
  lastStage,
  rampType,
}) => (
  <div className="active-position-container">
    <div
      style={
        rampType !== undefined &&
        rampType === 'off' &&
        currentStage === fieldPosition
          ? { background: '#946CFF' }
          : { background: '' }
      }
      className={`position ${
        currentStage < fieldPosition
          ? 'un-active-position'
          : currentStage > fieldPosition
          ? 'former-position'
          : ''
      }`}
    >
      <span>{fieldPosition}</span>
    </div>
    <div className="img-container">
      {lastStage === true ? null : currentStage === fieldPosition ? (
        <img
          src={
            currentStage === 4
              ? longActiveLine
              : rampType !== undefined && rampType === 'off'
              ? offRampActiveLine
              : activeLine
          }
          alt="active-line"
        />
      ) : null}

      {currentStage > fieldPosition && (
        <img
          src={
            fieldPosition > 3 && currentStage === 5
              ? inActiveLongLine
              : unActiveLine
          }
          alt="active-line"
        />
      )}
    </div>
  </div>
);

export default FieldPosition;
