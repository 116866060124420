/* eslint-disable @typescript-eslint/no-shadow */
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation, useNavigate } from 'react-router-dom';
import InputField from '../../components/Fields/InputField/InputField';
import { lock } from '../../assets';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Layout from '../../components/Layout/Layout';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import * as Yup from 'yup';

const Tier1Verification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;
  const email = location.state?.email;
  const rampType = location.state?.rampType;
  const [activeButton, setActiveButton] = useState<boolean>(true);

  return (
    <Layout>
      <ScrollToTop>
        <div className="basic-kyc">
          <TopPageTransactionInfo
            title={'PAYING'}
            rampType={rampType}
            amount={
              rampType === 'on'
                ? parseFloat(data?.currencyAmount).toLocaleString()
                : parseFloat(data?.amount)
                    .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                    .toLocaleString()
            }
            token={rampType === 'on' ? data?.currency : data?.token}
          />

          <div className="page-title">
            <h5 className="title">Just one more step, please</h5>
            <p className="description">
              We need your details to verify your identity. This is a one-time
              request.
            </p>

            <div className="privacy-container">
              <img src={lock} alt="" />
              <p>
                Your privacy is our priority! Rest assured that your information
                will never be used for any purpose other than what we have
                explicitly stated. Your data is safe with us.
              </p>
            </div>
          </div>
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
              firstName: '',
              lastName: '',
              middleName: '',
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required('This is a required field'),
              lastName: Yup.string().required('This is a required field'),
              middleName: Yup.string(),
            })}
            onSubmit={(values, { setErrors }) => {
              const errors = {};

              if (
                Object.entries(errors).length === 0 &&
                errors.constructor === Object
              ) {
                setActiveButton(false);
                navigate('/phone-verification', {
                  state: {
                    basicData: { ...values },
                    data,
                    email,
                    rampType,
                  },
                });
                ReactGA.event({
                  category: 'phone verification',
                  action: 'got to phone verification page button click',
                  label: 'phone_verification',
                });
              } else {
                setErrors(errors);
              }
            }}
          >
            {({ handleSubmit, isValid }) => (
              <Form className="form-control" onSubmit={handleSubmit}>
                <p className="kyc-title">PERSONAL INFORMATION</p>
                <InputField name="firstName" placeHolder="First name" />
                <InputField
                  name="middleName"
                  placeHolder="Middle name (Optional)"
                />
                <InputField name="lastName" placeHolder="Last name" />

                <PrimaryButton
                  validForm={isValid}
                  type={'submit'}
                  title={'Continue'}
                  activeButton={activeButton}
                />
              </Form>
            )}
          </Formik>
        </div>
      </ScrollToTop>
    </Layout>
  );
};

export default Tier1Verification;
