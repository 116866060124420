/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable autofix/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import { createSearchParams } from 'react-router-dom';
import type {
  DispatchTransactionType,
  IProcessKYC,
  ISerializedTxRes,
} from '../../../types';
import { Buffer } from 'buffer';

import { BASE_URL } from '../../utils/baseUrl';
import {
  config,
  configWithSession,
  configWithSessionFile,
} from '../../utils/config';
import {
  KYC_TIER_THREE,
  KYC_TIER_THREE_ERROR,
  GET_BANK_LIST,
  GET_RATE,
  GET_SUPPORTED_COUNTRIES,
  GET_SUPPORTED_FIAT,
  GET_SUPPORTED_ID,
  GET_SUPPORTED_TOKENS,
  INITIATE_TRANSACTION,
  INITIATE_TRANSACTION_ERROR,
  KYC_TIER_ONE,
  KYC_TIER_ONE_ERROR,
  KYC_TIER_TWO,
  KYC_TIER_TWO_ERROR,
  OFF_RAMP,
  ON_AND_OFF_RAMP_ERROR,
  ON_RAMP,
  PROCESS_KYC,
  PROCESS_KYC_ERROR,
  RESOLVE_BANK,
  RESOLVE_BANK_ERROR,
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_ERROR,
  VALIDATE_WALLET_ADDRESS,
  VALIDATE_WALLET_ADDRESS_ERROR,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  BASIC_INFORMATION,
  BASIC_INFORMATION_ERROR,
  VALIDATE_EMAIL_OTP,
  VALIDATE_EMAIL_OTP_ERROR,
  GET_COUNTRY_WITH_STATE,
  GET_LOCAL_GOVERNMENT,
  ADD_BANK,
  ADD_BANK_ERROR,
  GET_SAVED_BANKS,
  SOLANA_OFF_RAMP,
  VERIFY_SOLANA_OFF_RAMP,
} from './types';

import { type Connection, PublicKey, Transaction } from '@solana/web3.js';
import {
  encodeURL,
  type TransactionRequestURLFields,
  type TransferRequestURLFields,
} from '@solana/pay';
import BigNumber from 'bignumber.js';
import { buildSerializedTransaction, testRecipient } from '../../helper/solana';
import { SolanaCommitments } from '../../components/interface';
import type { Dispatch, SetStateAction } from 'react';
// eslint-disable-next-line @typescript-eslint/naming-convention

export const _INITIATE_TRANSACTION =
  (navigate: any, redirectTo: string, type: string) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/tx/initiate';
      const method = 'POST';
      const body = { type };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        config({ path, method, body, date })
      );

      dispatch({
        type: INITIATE_TRANSACTION,
        payload: res.data.data,
      });

      localStorage.setItem('session', res.data.data.token);

      const params: any = [
        ['email', ''],
        ['address', ''],
        ['session', res.data.data.token],
      ];
      if (redirectTo === 'on-ramp') {
        navigate({
          pathname: '/on-ramp',
          search: `?${createSearchParams(params)}`,
        });
      } else {
        navigate({
          pathname: '/off-ramp',
          search: `?${createSearchParams(params)}`,
        });
      }
    } catch (err: any) {
      const error = err?.response?.data?.message;
      dispatch({
        type: INITIATE_TRANSACTION_ERROR,
        payload: error,
      });
    }
  };

export const _VALIDATE_EMAIL =
  (
    email: string,
    rampType: string,
    data: any,
    navigate: any,
    setActiveButton: (v: boolean) => void
  ) =>
  async (dispatch: DispatchTransactionType | any) => {
    const amount: number = Number(data.amount);
    try {
      const date = new Date().toISOString();
      const path = '/webpay/kyc/email/verification';
      const method = 'POST';
      const body = { email, amount };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: VALIDATE_EMAIL,
        payload: res.data.data,
      });

      navigate('/confirm-email-otp', {
        state: { data, rampType, email },
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
      setActiveButton(true);
      dispatch({
        type: VALIDATE_EMAIL_ERROR,
        payload: error,
      });
    }
  };

export const _VALIDATE_EMAIL_OTP =
  (
    data: any,
    otp: string,
    email: string,
    rampType: string,
    navigate: any,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) =>
  async (dispatch: DispatchTransactionType | any) => {
    const amount: number = Number(data.amount);
    try {
      const date = new Date().toISOString();
      const path = '/webpay/kyc/email/verify';
      const method = 'POST';
      const body = { email, otp };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: VALIDATE_EMAIL_OTP,
        payload: res.data.data,
      });

      const tier = amount > 5000 ? 3 : amount > 250 ? 2 : 1;

      if (tier === 3 && res.data.data.tier_status === 'pending') {
        navigate('/transaction-status', {
          state: { data, status: 'tier_pending', rampType },
        });
      } else {
        if (
          res.data.data.verified === true &&
          res.data.data.current_tier >= tier
        ) {
          if (rampType === 'on') {
            dispatch(_ON_RAMP(data, email, navigate));
          } else {
            if (savedBanks.length > 0) {
              navigate('/saved-banks', {
                state: { data, rampType: 'off', email },
              });
            } else {
              navigate('/receiving-bank', {
                state: { ...data, rampType: 'off', email },
              });
            }
          }
        } else {
          if (res.data.data.verified === true) {
            if (res.data.data.current_tier === 0 && tier === 1) {
              navigate('/tier1-verification', {
                state: { data, rampType, email, goToTier: '' },
              });
            } else if (tier === 2) {
              if (res.data.data.current_tier === 0) {
                navigate('/tier1-verification', {
                  state: {
                    data,
                    rampType,
                    email,
                    goToTier: 'tier2-verification',
                  },
                });
              } else if (res.data.data.current_tier === 1) {
                navigate('/tier2-verification', {
                  state: { data, rampType, email, goToTier: '' },
                });
                // eslint-disable-next-line no-useless-return
              } else return;
            } else if (tier === 3) {
              if (res.data.data.current_tier === 0) {
                navigate('/tier1-verification', {
                  state: {
                    data,
                    rampType,
                    email,
                    goToTier: 'tier2-verification',
                  },
                });
              } else if (res.data.data.current_tier === 1) {
                navigate('/tier2-verification', {
                  state: {
                    data,
                    rampType,
                    email,
                    goToTier: 'tier3-verification',
                  },
                });
              } else if (res.data.data.current_tier === 2) {
                navigate('/tier3-verification', {
                  state: { data, rampType, email, goToTier: '' },
                });
              }
              // eslint-disable-next-line no-useless-return
            } else return;
          }
        }
      }
    } catch (err: any) {
      const error = err?.response?.data?.message;
      setActiveButton(true);
      dispatch({
        type: VALIDATE_EMAIL_OTP_ERROR,
        payload: error,
      });
    }
  };

export const _PROCESS_KYC =
  (
    kycData: IProcessKYC,
    email: string,
    data: any,
    navigate: any,
    rampType: string,
    setLoading: (v: boolean) => void
  ) =>
  async (dispatch: DispatchTransactionType | any) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/kyc/biometrics/verify';
      const method = 'POST';
      const body = { ...kycData, email };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: PROCESS_KYC,
        payload: res.data.data,
      });

      setLoading(true);

      if (res.data.data.verified === true) {
        if (rampType === 'on') {
          dispatch(_ON_RAMP(data, email, navigate));
        } else {
          const existingAddress: any = localStorage.getItem('address');
          const USDT_BEP_20_WALLET = process.env.REACT_APP_USDT_BEP_20_WALLET;
          const USDT_TRC_20_WALLET = process.env.REACT_APP_USDT_TRC_20_WALLET;
          const { network } = data;

          const setWalletAddress = (
            address: any,
            bep_address: any,
            trc_address: any
          ) => {
            if (address !== null && address.length > 10) {
              return address;
            } else if (network.toLowerCase() === 'bep20') {
              return bep_address;
            } else if (network.toLowerCase() === 'tron') {
              return trc_address;
            } else {
              return '';
            }
          };

          dispatch(
            _OFF_RAMP(
              data,
              setWalletAddress(
                existingAddress,
                USDT_BEP_20_WALLET,
                USDT_TRC_20_WALLET
              ),
              email,
              navigate
            )
          );
        }
      } else {
        navigate('/transaction-status', {
          state: { data, rampType, status: 'kyc_failed' },
        });
      }
    } catch (err: any) {
      const error = err?.response?.data?.message;

      dispatch({
        type: PROCESS_KYC_ERROR,
        payload: error,
      });
    }
  };

export const _GET_RATES =
  (token: string, network: string, currency: string) =>
  async (dispatch: DispatchTransactionType) => {
    console.log('🚀 ~ currency:', currency);
    console.log('🚀 ~ network:', network);
    console.log('🚀 ~ token:', token);
    try {
      const date = new Date().toISOString();
      const path = `/webpay/tx/rates?token=${token}&network=${network}&currency=${currency}`;
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: GET_RATE,
        payload: res?.data?.data,
      });
    } catch (err: any) {
      const error: any = err?.response?.data?.message;
    }
  };

export const _GET_SUPPORTED_TOKEN =
  () => async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/misc/supported-assets';
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: GET_SUPPORTED_TOKENS,
        payload: res?.data?.data,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
    }
  };

export const _GET_BANK_LIST =
  () => async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/misc/banks';
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: GET_BANK_LIST,
        payload: res.data.data.banks,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
    }
  };

export const _RESOLVE_BANK =
  (
    bank_code: string,
    account_number: string,
    setLoading: (v: boolean) => void
  ) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/misc/bank/resolve';
      const method = 'POST';
      const body = { bank_code, account_number };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: RESOLVE_BANK,
        payload: res.data.data,
      });

      setLoading(false);
    } catch (err: any) {
      const error = err?.response?.data;
      dispatch({
        type: RESOLVE_BANK_ERROR,
        payload: error,
      });

      setLoading(false);
    }
  };

export const _ON_RAMP =
  (
    data: any,
    email: string,
    navigate: any,
    kycFormData?: any,
    setKycData?: any
  ) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/tx/onramp';
      const method = 'POST';
      const { currencyAmount, network, token, currency, walletAddress } = data;
      const amount = parseInt(currencyAmount);
      const address = walletAddress;
      const reference = localStorage.getItem('reference');
      const session = localStorage.getItem('session');

      const body: any = {
        amount,
        network,
        token,
        currency,
        address,
        email,
        session,
      };

      if (reference !== 'null') {
        body.reference = reference;
      }

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: ON_RAMP,
        payload: res.data.data,
      });

      navigate('/transfer', { state: { data } });
    } catch (err: any) {
      const error = err?.response?.data?.message;
      console.log('🚀 ~ err?.response?.data:', err?.response);
      dispatch({
        type: ON_AND_OFF_RAMP_ERROR,
        payload: error,
      });
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (kycFormData) setKycData(kycFormData);
    }
  };

export const _OFF_RAMP =
  (
    data: any,
    address: string,
    email: string,
    navigate: any,
    kycFormData?: any,
    setKycData?: any,
    setActiveButton?: any
  ) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/tx/offramp';
      const method = 'POST';

      const { amount, network, token, currency, account_number, bank_code } =
        data;
      const reference = localStorage.getItem('reference');
      const session = localStorage.getItem('session');

      const recipient_bank_info = {
        account_number,
        bank_code,
      };
      const convertedAmount = Number(amount);

      const body: any = {
        address,
        amount: convertedAmount,
        network,
        token,
        currency,
        email,
        recipient_bank_info,
        session,
      };

      if (reference !== 'null') {
        body.reference = reference;
      }

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: OFF_RAMP,
        payload: res.data.data,
      });

      navigate('/send-crypto', { state: { data } });
    } catch (err: any) {
      const error = err?.response?.data?.message;
      setActiveButton?.(true);
      dispatch({
        type: ON_AND_OFF_RAMP_ERROR,
        payload: error,
      });

      if (kycFormData) setKycData(kycFormData);
    }
  };

export const _VALIDATE_WALLET_ADDRESS =
  (address: string, network: string, setError: (v: boolean) => void) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/misc/address/validate';
      const method = 'POST';
      const body = { address, network };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: VALIDATE_WALLET_ADDRESS,
        payload: res.data.data,
      });

      setError(res.data.data.valid);
    } catch (err: any) {
      const error = err.response.data.message;
      dispatch({
        type: VALIDATE_WALLET_ADDRESS_ERROR,
        payload: error,
      });

      setError(true);
    }
  };

export const _KYC_TIER_THREE =
  (
    data: any,
    email: string,
    rampType: string,
    address: string,
    postal_code: string,
    state: string,
    lga: string,
    proof_of_address: any,
    navigate: any,
    setChecking: (v: boolean) => void,
    setErrorMessage: (v: object) => void,
    setKycData: (v: object) => void,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) =>
  async (dispatch: DispatchTransactionType | any) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/kyc/tier3/verify';
      const method = 'POST';

      const formData = new FormData();
      formData.append('email', email);
      formData.append('address', address);
      formData.append('postal_code', postal_code);
      formData.append('state', state);
      formData.append('lga', lga);
      formData.append('proof_of_address', proof_of_address);

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        formData,
        configWithSessionFile({ path, method, body: formData, date })
      );

      dispatch({
        type: KYC_TIER_THREE,
        payload: res.data.data,
      });

      if (res.data.data.status === 'pending') {
        navigate('/transaction-status', {
          state: { data, status: 'tier_pending', rampType },
        });
      } else {
        if (res.data.data.verified === true) {
          const kycFormData = {
            email,
            address,
            postal_code,
            state,
            lga,
            proof_of_address,
          };

          if (rampType === 'on') {
            dispatch(_ON_RAMP(data, email, navigate, kycFormData, setKycData));
          } else {
            if (savedBanks.length > 0) {
              navigate('/saved-banks', {
                state: { data, rampType, email },
              });
            } else {
              navigate('/receiving-bank', {
                state: { ...data, rampType: 'off', email },
              });
            }
          }
        } else {
          setErrorMessage(res.data.data);
          setChecking(false);
          setTimeout(() => {
            setErrorMessage({});
          }, 4000);
          setActiveButton(true);
          setKycData({
            email,
            address,
            postal_code,
            state,
          });
        }
      }
    } catch (err: any) {
      const error = err?.response?.data?.message;
      dispatch({
        type: KYC_TIER_THREE_ERROR,
        payload: error,
      });
      setErrorMessage(error);
      setChecking(false);
      setTimeout(() => {
        setErrorMessage({});
      }, 4000);
      setKycData({
        email,
        address,
        postal_code,
        state,
        lga,
        proof_of_address,
      });
    }
  };

export const _GET_SUPPORTED_FIAT =
  () => async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/misc/supported-fiats';
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: GET_SUPPORTED_FIAT,
        payload: res?.data?.data,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
    }
  };

export const _GET_SUPPORTED_ID =
  () => async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/misc/supported-ids';
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: GET_SUPPORTED_ID,
        payload: res.data.data,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
    }
  };

export const _GET_SUPPORTED_COUNTRIES =
  () => async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/misc/supported-countries';
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: GET_SUPPORTED_COUNTRIES,
        payload: res.data.data,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
    }
  };

export const _GET_COUNTRY_WITH_STATE =
  (email: string) => async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = `/webpay/kyc/country?email=${email}`;
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({ path: '/webpay/kyc/country', method, body, date })
      );

      dispatch({
        type: GET_COUNTRY_WITH_STATE,
        payload: res.data.data,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
    }
  };

export const _GET_LOCAL_GOVERNMENT =
  (email: string, state: string) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = `/webpay/kyc/lgas?email=${email}&state=${state}`;
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({ path: '/webpay/kyc/lgas', method, body, date })
      );

      dispatch({
        type: GET_LOCAL_GOVERNMENT,
        payload: res.data.data,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
    }
  };

export const _KYC_TIER_ONE =
  (
    basicData: any,
    data: any,
    email: string,
    phone_number: string,
    country: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setActiveButton?: (v: boolean) => void
  ) =>
  async (dispatch: DispatchTransactionType | any) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/kyc/tier1/verification';
      const method = 'POST';

      const body = {
        country,
        email,
        phone: phone_number,
      };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: KYC_TIER_ONE,
        payload: res.data.data,
      });

      navigate('/confirm-otp', {
        state: {
          data,
          basicData,
          rampType,
          email,
          phone_number,
          country,
          goToTier,
        },
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
      setActiveButton?.(true);
      dispatch({
        type: KYC_TIER_ONE_ERROR,
        payload: error,
      });
    }
  };

export const _KYC_TIER_TWO =
  (
    data: any,
    kycData: IProcessKYC,
    email: string,
    id_type: string,
    id_number: string,
    country: string,
    dob: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setLoading: (v: boolean) => void,
    savedBanks: any
  ) =>
  async (dispatch: DispatchTransactionType | any) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/kyc/tier2/verify';
      const method = 'POST';

      const body = {
        email,
        ...kycData,
        id_type,
        id_number,
        country,
        dob,
      };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: KYC_TIER_TWO,
        payload: res.data.data,
      });

      if (goToTier !== undefined && goToTier !== '') {
        navigate(`/${goToTier}`, {
          state: { data, rampType, email, goToTier: '' },
        });
      } else {
        if (res.data.data.verified === true) {
          if (rampType === 'on') {
            dispatch(_ON_RAMP(data, email, navigate));
          } else {
            if (savedBanks.length > 0) {
              navigate('/saved-banks', {
                state: { data, rampType, email },
              });
            } else {
              navigate('/receiving-bank', {
                state: { ...data, rampType: 'off', email },
              });
            }
          }
        } else {
          setLoading(false);
          dispatch({
            type: KYC_TIER_TWO_ERROR,
            payload: res.data.data,
          });
        }
      }
    } catch (err: any) {
      const error = err?.response?.data;
      setLoading(false);
      dispatch({
        type: KYC_TIER_TWO_ERROR,
        payload: error,
      });
    }
  };

export const _VERIFY_OTP =
  (
    data: any,
    otp: string,
    email: string,
    phone_number: string,
    country: string,
    firstname: string,
    middlename: string,
    lastname: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) =>
  async (dispatch: DispatchTransactionType | any) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/kyc/tier1/verify/otp';
      const method = 'POST';

      const body = {
        email,
        phone: phone_number,
        country,
        otp,
        firstname,
        middlename,
        lastname,
      };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: VERIFY_OTP,
        payload: res.data.data,
      });

      if (goToTier !== undefined && goToTier !== '') {
        navigate(`/${goToTier}`, {
          state: { data, rampType, email, goToTier: '' },
        });
      } else {
        if (res.data.data.verified === true) {
          if (rampType === 'on') {
            dispatch(_ON_RAMP(data, email, navigate));
          } else {
            if (savedBanks.length > 0) {
              navigate('/saved-banks', {
                state: { data, rampType, email },
              });
            } else {
              navigate('/receiving-bank', {
                state: { ...data, rampType: 'off', email },
              });
            }
          }
        }
      }
    } catch (err: any) {
      const error = err?.response?.data?.message;
      setActiveButton(true);
      dispatch({
        type: VERIFY_OTP_ERROR,
        payload: error,
      });
    }
  };

export const _BASIC_INFORMATION =
  (
    email: string,
    firstName: string,
    middleName: string,
    lastName: string,
    rampType: string,
    data: any,
    setErrorMessage: (v: any) => void,
    setKycData: (v: any) => void,
    navigate: any,
    setActiveButton: (v: boolean) => void
  ) =>
  async (dispatch: DispatchTransactionType | any) => {
    const amount: number = Number(data.amount);
    try {
      const date = new Date().toISOString();
      const path = '/webpay/kyc/user/info';
      const method = 'POST';
      const body = {
        email,
        amount,
        firstname: firstName,
        middlename: middleName,
        lastname: lastName,
      };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: BASIC_INFORMATION,
        payload: res.data.data,
      });

      if (res.data.data.verified === true) {
        if (res.data.data.tier === 1) {
          navigate('/tier1-verification', {
            state: { data, rampType, email, goToTier: '' },
          });
        } else if (res.data.data.tier === 2) {
          if (res.data.data.phone_verified === true) {
            navigate('/tier2-verification', {
              state: { data, rampType, email, goToTier: '' },
            });
          } else {
            navigate('/tier1-verification', {
              state: { data, rampType, email, goToTier: 'tier2-verification' },
            });
          }
        } else if (res.data.data.tier === 3) {
          if (res.data.data.phone_verified === true) {
            navigate('/tier3-verification', {
              state: { data, rampType, email, goToTier: '' },
            });
          } else {
            navigate('/tier1-verification', {
              state: { data, rampType, email, goToTier: 'tier3-verification' },
            });
          }
        }
      }
    } catch (err: any) {
      const error = err?.response?.data?.message;
      setActiveButton(true);
      setErrorMessage(error);
      setKycData({ firstName, middleName, lastName });
      dispatch({
        type: BASIC_INFORMATION_ERROR,
        payload: error,
      });
    }
  };

export const _ADD_BANK =
  (
    email: string,
    bank_code: string,
    account_number: string,
    transactionData: any,
    navigate: any,
    setActiveButton: any
  ) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = `/webpay/misc/saved/bank`;
      const method = 'POST';
      const body = { email, bank_code, account_number };

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({
          path: '/webpay/misc/saved/bank',
          method,
          body,
          date,
        })
      );

      dispatch({
        type: ADD_BANK,
        payload: res.data.data,
      });

      navigate('/confirm-transaction', {
        state: {
          data: { ...transactionData },
          backUrl: '/confirm-account',
          rampType: 'off',
        },
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
      setActiveButton(true);
      dispatch({
        type: ADD_BANK_ERROR,
        payload: error,
      });
    }
  };

export const _GET_SAVED_BANK =
  (email: string) => async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = `/webpay/misc/saved/banks?email=${email}`;
      const method = 'GET';
      const body = {};

      const res: any = await axios.get(
        `${BASE_URL}${path}`,
        configWithSession({
          path: '/webpay/misc/saved/banks',
          method,
          body,
          date,
        })
      );

      dispatch({
        type: GET_SAVED_BANKS,
        payload: res.data.data,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
      // console.log("🚀 ~ file: transactionActions.ts:1143 ~ error:", error)
    }
  };

export const _SOLANA_OFF_RAMP =
  ({
    data,
    address,
    email,
    navigate,
    signTransaction,
    connection,
    kycFormData,
    setKycData,
    setActiveButton,
    setTxReference,
    rampType,
  }: {
    data: any;
    address: string;
    email: string;
    navigate: any;
    signTransaction: any;
    connection: Connection;
    kycFormData?: any;
    setKycData?: any;
    setActiveButton?: any;
    setTxReference: (v: string) => void;
    rampType: 'on' | 'off' | undefined;
  }) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = '/webpay/tx/offramp/solana-pay';
      const method = 'POST';

      const { amount, network, token, currency, account_number, bank_code } =
        data;

      const reference = localStorage.getItem('reference');
      const session = localStorage.getItem('session');

      const recipient_bank_info = {
        account_number,
        bank_code,
      };
      const convertedAmount = Number(amount);

      const body: any = {
        address,
        amount: convertedAmount,
        network,
        token,
        currency,
        email,
        recipient_bank_info,
        session,
      };

      if (reference !== 'null') {
        body.reference = reference;
      }

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        configWithSession({ path, method, body, date })
      );

      dispatch({
        type: SOLANA_OFF_RAMP,
        payload: res?.data?.data?.solanaPayTx,
      });

      // const serializeTransaction = await buildSerializedTransaction({
      //   addresses: {
      //     account: address,
      //     destinationAddress: testRecipient.recipient,
      //   },
      //   amount: 0.2,
      //   memo: 'asdfauia',
      //   connection,
      // });

      // const transaction = Transaction.from(Buffer.from());
      // const transaction = Transaction.from(
      //   Buffer.from(serializeTransaction, 'base64')
      // );
      // if (!signTransaction) {
      //   alert('Wallet does not support transaction signing.');
      //   return;
      // }
      const transaction = Transaction.from(
        Buffer.from(
          res?.data?.data?.solanaPayTx?.serializedTransaction,
          'base64'
        )
      );

      const signedTransaction = await signTransaction(transaction);
      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize()
      );

      try {
        const latestBlockHash = await connection.getLatestBlockhash();
        await connection.confirmTransaction(
          {
            signature,
            blockhash: latestBlockHash.blockhash,
            lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
          },
          SolanaCommitments.Confirmed
        );

        setActiveButton(true);
        const scalexRef = res?.data?.data?.scalexTx?.reference;
        console.log('🚀 ~ scalexRef:', scalexRef);
        setTxReference(scalexRef);
        navigate('/transaction-status', {
          state: {
            data,
            status: 'tier_pending',
            rampType,
            reference: scalexRef,
          },
        });
      } catch (error) {
        setActiveButton(true);
        const err = 'Failed to complete transaction';
        dispatch({
          type: ON_AND_OFF_RAMP_ERROR,
          payload: err,
        });
      } finally {
        setActiveButton(true);
      }

      // await connection.confirmTransaction({
      //   signature,
      //   blockhash: latestBlockHash.blockhash,
      //   lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
      // });
      // navigate('/send-crypto', {
      //   state: {
      //     data,
      //     address: res?.data?.data?.scalexTx.wallet_to_fund.address,
      //     scalexReference: res?.data?.data?.scalexTx.reference,
      //     reference: res?.data?.data?.solanaPayTx?.reference,
      //   },
      // });
    } catch (err: any) {
      const error = err?.response?.data?.message;
      console.log('🚀 ~ error:', err);
      setActiveButton?.(true);
      dispatch({
        type: ON_AND_OFF_RAMP_ERROR,
        payload: error,
      });

      if (kycFormData) setKycData(kycFormData);
    }
  };

export const _SOLANA_OFF_RAMP_MOBILE =
  // (
  //   data: any,
  //   // address: string,
  //   email: string,
  //   navigate: any,
  //   // signTransaction?: any,
  //   // connection?: any,
  //   kycFormData?: any,
  //   setKycData?: any,
  //   setActiveButton?: any
  // ) =>


    ({
      data,
      // address,
      email,
      navigate,
      // signTransaction,
      // connection,
      kycFormData,
      setKycData,
      setSolTxRef,
      setActiveButton,
      setTxReference,
      setIsTxInitiated,
    }: {
      data: any;
      email: string;
      navigate: any;
      kycFormData?: any;
      setKycData?: any;
      setActiveButton?: any;
      setSolTxRef: Dispatch<SetStateAction<string>>;
      setTxReference: Dispatch<SetStateAction<string>>;
      setIsTxInitiated: Dispatch<SetStateAction<boolean>>;
    }) =>
    async (dispatch: DispatchTransactionType) => {
      try {
        const date = new Date().toISOString();
        const path = '/webpay/tx/offramp/solana-pay';
        const method = 'POST';

        const { amount, network, token, currency, account_number, bank_code } =
          data;
        const reference = localStorage.getItem('reference');
        const session = localStorage.getItem('session');

        const recipient_bank_info = {
          account_number,
          bank_code,
        };
        const convertedAmount = Number(amount);

        const body: any = {
          // address,
          amount: convertedAmount,
          network,
          token,
          currency,
          email,
          recipient_bank_info,
          session,
        };

        if (reference !== 'null') {
          body.reference = reference;
        }

        const res: any = await axios.post(
          `${BASE_URL}${path}`,
          body,
          configWithSession({ path, method, body, date })
        );
        setIsTxInitiated(true);
        dispatch({
          type: SOLANA_OFF_RAMP,
          payload: res?.data?.data?.solanaPayTx,
        });

        const transaction = Transaction.from(
          Buffer.from(
            res?.data?.data?.solanaPayTx?.serializedTransaction,
            'base64'
          )
        );

        const solanaPayTx = res?.data?.data?.solanaPayTx;
        if (solanaPayTx) {
          const {
            recipient,
            amount: amt,
            reference: ref,
            label,
            message,
            memo,
            splToken,
          } = solanaPayTx;
          setSolTxRef(ref);
          const scalexRef = res?.data?.data?.scalexTx?.reference;
          setTxReference(scalexRef);
          const formattedAmount = new BigNumber(amt);
          const formattedRecipient = new PublicKey(recipient);
          const formattedRef = new PublicKey(ref);
          const formattedSplToken = new PublicKey(splToken);

          const params: TransferRequestURLFields = {
            recipient: formattedRecipient,
            amount: formattedAmount,
            reference: formattedRef,
            label,
            message,
            memo,
            splToken: formattedSplToken,
          };
          try {
            const transactionUrl = `https://ramp.scalex.africa/solana-pay?reference=${ref}&amount=${amt}`;

            const urlParams: TransactionRequestURLFields = {
              link: new URL(transactionUrl),
              label,
              message,
            };
            const encodedURL = encodeURL(urlParams);
            window.location.href = encodedURL.href;
          } catch (error) {
            dispatch({
              type: ON_AND_OFF_RAMP_ERROR,
              payload: 'No wallet found, please install a wallet',
            });
          }
        } else {
          navigate('/transaction-status', {
            state: {
              data,
              status: 'tier_pending',
              rampType: 'off',
              reference: res?.data?.data?.scalexTx?.reference,
            },
          });
        }
      } catch (err: any) {
        const error = err?.response?.data?.message;
        setActiveButton?.(true);
        dispatch({
          type: ON_AND_OFF_RAMP_ERROR,
          payload: err,
        });

        if (kycFormData) setKycData(kycFormData);
      }
    };

export const _VERIFY_SOLANA_PAY =
  (reference: string, setIsTxInitiated?: Dispatch<SetStateAction<boolean>>) =>
  async (dispatch: DispatchTransactionType) => {
    try {
      const date = new Date().toISOString();
      const path = `/webpay/tx/offramp/solana-pay/verify`;
      const method = 'POST';
      const body = { reference };

      const headerConfig = configWithSession({
        path,
        method,
        body,
        date,
      });

      const res: any = await axios.post(
        `${BASE_URL}${path}`,
        body,
        headerConfig
      );
      setIsTxInitiated?.(false);
      dispatch({
        type: VERIFY_SOLANA_OFF_RAMP,
        payload: res.data.data,
      });
    } catch (err: any) {
      const error = err?.response?.data?.message;
      // dispatch({
      //   type: ON_AND_OFF_RAMP_ERROR,
      //   payload: error,
      // });
    }
  };
