import React, { Fragment } from 'react';
import type { FC } from 'react';

interface Props {
  errorMessage?: string;
  icon?: string;
}

const ErrorField: FC<Props> = ({ errorMessage, icon }) => (
  <Fragment>
    {errorMessage != null && (
      <div className="error-field ">
        <img src={icon} alt="fee-icon" />
        <p className="error-message">{errorMessage}</p>
      </div>
    )}
  </Fragment>
);

export default ErrorField;
