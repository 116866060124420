import { type FC, useState } from 'react';
import { useField } from 'formik';
import { FileIcon } from '../../../assets';

interface Props {
  text: string;
  name: string;
  setFieldValue: any;
}

const FileUpload: FC<Props> = ({ text, setFieldValue, name }) => {
  const [field, meta] = useField(name);

  const [selectedID, setSelectedID] = useState<any>(null);
  const [isIDselected, setIsIDselected] = useState(false);

  const handleProofOfAddress = (e: any) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedID({ img: reader.result });
      }
    };
    reader.readAsDataURL(file);
    setIsIDselected(true);
    setFieldValue(file);
  };

  return (
    <div className="upload-container">
      <div className="file">
        <input type="file" name={field.name} onChange={handleProofOfAddress} />
        {selectedID !== null ? (
          <div className="preview--image">
            <img src={selectedID?.img} alt="" />
          </div>
        ) : (
          <img src={FileIcon} alt="" />
        )}{' '}
        {!isIDselected && (
          <>
            <p>{text}</p>
            <span>Accepts PNG, JPG, JPEG and PDF</span>
          </>
        )}
      </div>
      {meta.error != null && <p className="upload-error-text ">{meta.error}</p>}
    </div>
  );
};

export default FileUpload;
