/* eslint-disable @typescript-eslint/no-shadow */
import { useState, type FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import PageLogo from '../../components/PageLogo/PageLogo';
import { Form, Formik } from 'formik';
import ReactGA from 'react-ga';
import * as Yup from 'yup';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { errorIcon, logo, logoBg } from '../../assets';
import OtpInput from 'react-otp-input';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  _GET_SAVED_BANK,
  _VERIFY_OTP,
} from '../../redux/transaction/transactionActions';
import { selectVerifyOTPError } from '../../redux/transaction/transactionSelectors';
import FieldStatus from '../../components/Fields/FieldStatus/FieldStatus';
import ResendTimer from '../../components/ResendTimer/ResendTimer';

interface Props {
  verifyOTPError: any;
  _VERIFY_OTP: (
    data: any,
    otp: string,
    email: string,
    phoneNumber: string,
    country: string,
    firstname: string,
    middlename: string,
    lastname: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) => void;
}

const VerifyOtp: FC<Props> = ({ verifyOTPError, _VERIFY_OTP }) => {
  const location = useLocation();
  const navigate: any = useNavigate();
  const dispatch: any = useDispatch();
  const data = location.state?.data;
  const basicData = location.state?.basicData;
  const rampType = location.state?.rampType;
  const email = location.state?.email;
  const phoneNumber = location.state?.phone_number;
  const country = location.state?.country;
  const goToTier = location.state?.goToTier;
  const text = 'Verify your phone number to continue';
  const [activeButton, setActiveButton] = useState<boolean>(true);

  const onAndOffRampError = useSelector(
    (state: any) => state.transaction.onAndOffRampError
  );

  const savedBanks = useSelector(
    (state: any) => state.transaction.getSavedBanks
  );

  useEffect(() => {
    dispatch(_GET_SAVED_BANK(email));
  }, [dispatch, email]);

  return (
    <Layout>
      <ScrollToTop>
        <TopPageTransactionInfo
          title={'PAYING'}
          rampType={rampType}
          amount={
            rampType === 'on'
              ? parseFloat(data?.currencyAmount).toLocaleString()
              : parseFloat(data?.amount)
                  .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                  .toLocaleString()
          }
          token={rampType === 'on' ? data?.currency : data?.token}
        />

        <div className="top-welcome-page-content">
          <PageLogo iconBg={logoBg} icon={logo} />
          <h1 className="welcome-message">{text}</h1>
        </div>

        <Formik
          enableReinitialize
          validateOnMount
          key={'code'}
          initialValues={{
            code: '',
          }}
          validationSchema={Yup.object({
            code: Yup.string()
              .min(6, 'Please enter a valid otp')
              .max(6, 'Please enter a valid otp')
              .required('This is a required field'),
          })}
          onSubmit={(values, { setErrors }) => {
            const { code } = values;
            const errors = {};

            if (
              Object.entries(errors).length === 0 &&
              errors.constructor === Object
            ) {
              setActiveButton(false);
              _VERIFY_OTP(
                data,
                code,
                email,
                phoneNumber,
                country,
                basicData.firstName,
                basicData.middleName,
                basicData.lastName,
                rampType,
                goToTier,
                navigate,
                setActiveButton,
                savedBanks
              );
              ReactGA.event({
                category: 'verify-otp',
                action: 'submit otp verification button',
                label: 'verify_otp',
              });
            } else {
              setErrors(errors);
            }
          }}
        >
          {({ handleSubmit, isValid, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <div className="verify-email-content">
                <div>
                  <OtpInput
                    value={values.code}
                    onChange={(e) => {
                      setFieldValue('code', e);
                    }}
                    inputStyle={{
                      width: 40,
                      height: 40,
                      borderRadius: 10,
                      fontSize: 17,
                      fontFamily: 'Mabry-Medium',
                      background: '#ECEFF1',
                    }}
                    containerStyle={{
                      gap: 2,
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    numInputs={6}
                    renderSeparator={<span> - </span>}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        type="tel"
                        inputMode="numeric"
                        name={`otp-input-${index}`}
                      />
                    )}
                  />

                  <ResendTimer basicData={basicData} />

                  <PrimaryButton
                    title="Verify code"
                    type="submit"
                    validForm={isValid}
                    activeButton={activeButton}
                    onClick={() => {}}
                    style={{ width: 335 }}
                  />

                  {(verifyOTPError !== null || onAndOffRampError !== null) && (
                    <FieldStatus
                      icon={errorIcon}
                      mode={'error'}
                      errorMessage={
                        onAndOffRampError !== null
                          ? onAndOffRampError
                          : verifyOTPError
                      }
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ScrollToTop>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  verifyOTPError: selectVerifyOTPError,
});

const mapDispatchToProps = (dispatch: any) => ({
  _VERIFY_OTP: (
    data: any,
    otp: string,
    email: string,
    phoneNumber: string,
    country: string,
    firstname: string,
    middlename: string,
    lastname: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) =>
    dispatch(
      _VERIFY_OTP(
        data,
        otp,
        email,
        phoneNumber,
        country,
        firstname,
        middlename,
        lastname,
        rampType,
        goToTier,
        navigate,
        setActiveButton,
        savedBanks
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);
