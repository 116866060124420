/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { createStructuredSelector } from 'reselect';

import Layout from '../../components/Layout/Layout';
import RampTopBar from '../../components/OnRampComponents/RampTopBar/RampTopBar';
import { offRampActiveIcon } from '../../assets';
import OffRampFormik from '../../components/OffRampFormik/OffRampFormik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingState from '../../components/LoadingState/LoadingState';
import { connect } from 'react-redux';
import {
  _GET_RATES,
  _GET_SUPPORTED_FIAT,
  _GET_SUPPORTED_TOKEN,
} from '../../redux/transaction/transactionActions';
import {
  selectSupportedFiat,
  selectSupportedTokens,
  selectTransactionLoading,
  selectTransactionRates,
} from '../../redux/transaction/transactionSelectors';
import type {
  // IRateData,
  ISupportedFiatData,
  ISupportedTokenData,
} from '../../../types';
// import { fixedGasFee } from '../../utils';

interface Props {
  _GET_RATES: (token: string, network: string, currency: string) => void;
  _GET_SUPPORTED_TOKEN: () => void;
  _GET_SUPPORTED_FIAT: () => void;
  rates: any;
  transactionLoad: boolean;
  supportedTokens: ISupportedTokenData[];
  supportedFiat: ISupportedFiatData[];
}

const OffRamp: FC<Props> = ({
  _GET_RATES,
  _GET_SUPPORTED_TOKEN,
  _GET_SUPPORTED_FIAT,
  rates,
  supportedTokens,
  supportedFiat,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageLoadState = location?.state?.loading;
  const [loading, setLoading] = useState<boolean>(
    pageLoadState !== undefined ? pageLoadState : true
  );

  const [params] = useSearchParams();
  const countdownStartTime: any = 1800;
  const paymentReceivedTime: any = 1800;

  // const getFee = (value: any, rampTypeFee: number) => {
  //   const pRampTypeFee = rampTypeFee / 100;

  //   const gasFee = fixedGasFee(network);
  //   const fee: any = pRampTypeFee * value + gasFee;
  //   return fee < 100 ? fee : 100;
  // };

  const email: string | undefined | null = params.get('email');
  const session: string | undefined | null | any = params.get('session');
  const address: string | any | null = params.get('address');
  const token: string | any | null = params.get('token');
  const network: string | any | null = params.get('network');
  const currency: string | any | null = params.get('currency');
  const stringAmount: number | any | null = params.get('amount');
  const skipRampForm: string | any | null = params.get('skipRampForm');
  const reference: string | any | null = params.get('reference');

  const amount = Number(stringAmount);
  const loadingTime = skipRampForm === 'true' ? 5000 : 2000;

  localStorage.setItem('reference', reference);
  localStorage.setItem('session', session);

  const percentageFee = rates?.feeInDetail?.offramp?.percentageFee;
  const rate = rates?.offramp?.rate_in_ngn;
  const numberFee = rates?.feeInDetail?.offramp?.totalFee;
  const toGet = String(((amount - numberFee) * rate).toFixed(2));
  const stringFee = String(numberFee);
  const currencyAmount = String(Number(amount) * rate);

  if (session !== null) {
    localStorage.setItem('session', session);
  }

  if (address !== null) {
    localStorage.setItem('address', address);
  }

  if (email !== null) {
    localStorage.setItem('email', email);
  }

  localStorage.setItem('countdownStartTime', countdownStartTime);
  localStorage.setItem('paymentReceivedTime', paymentReceivedTime);

  useEffect(() => {
    _GET_SUPPORTED_TOKEN();
    _GET_SUPPORTED_FIAT();
    _GET_RATES(token, network, currency);
  }, [
    _GET_RATES,
    _GET_SUPPORTED_TOKEN,
    _GET_SUPPORTED_FIAT,
    token,
    network,
    currency,
  ]);

  const test = false;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      if (
        skipRampForm !== null &&
        skipRampForm !== undefined &&
        skipRampForm === 'true'
      ) {
        navigate('/verify-email', {
          state: {
            data: {
              email,
              amount,
              currency,
              currencyAmount,
              fee: stringFee,
              network,
              percentageFee,
              rate,
              toGet,
              token,
              walletAddress: address,
            },
            rampType: 'off',
          },
        });
      }
    }, loadingTime);
  }, [
    address,
    amount,
    currency,
    currencyAmount,
    email,
    navigate,
    network,
    percentageFee,
    rate,
    skipRampForm,
    stringFee,
    toGet,
    token,
    loadingTime,
    test,
  ]);

  return (
    <Layout>
      {loading ? (
        <LoadingState />
      ) : (
        <div className="ramp-main">
          <RampTopBar icon={offRampActiveIcon} rampType="off" />
          <OffRampFormik
            rates={rates}
            supportedTokens={supportedTokens}
            supportedFiat={supportedFiat}
            _GET_RATES={_GET_RATES}
          />
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  rates: selectTransactionRates,
  supportedTokens: selectSupportedTokens,
  transactionLoad: selectTransactionLoading,
  supportedFiat: selectSupportedFiat,
});

const mapDispatchToProps = (dispatch: any) => ({
  _GET_RATES: (token: string, network: string, currency: string) =>
    dispatch(_GET_RATES(token, network, currency)),
  _GET_SUPPORTED_TOKEN: () => dispatch(_GET_SUPPORTED_TOKEN()),
  _GET_SUPPORTED_FIAT: () => dispatch(_GET_SUPPORTED_FIAT()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OffRamp);
