import type { FC } from 'react';
import { logoBg } from '../../assets';

interface PageLogoProps {
  icon: string;
  iconBg?: string;
  floatIcon?: string;
  type?: boolean;
}

const PageLogo: FC<PageLogoProps> = ({ icon, iconBg, type, floatIcon }) => (
  <div className="welcome-page-logo-container">
    <img
      className="logo-bg"
      src={iconBg !== undefined ? iconBg : logoBg}
      alt="logo-bg"
    />

    <div className={type === true ? 'response-icon-bg' : 'logo-container'}>
      <img className="logo-icon" src={icon} alt="" />
      <img src={floatIcon} className="float-icon" alt="" />
    </div>
  </div>
);

export default PageLogo;
