/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, type FC } from 'react';
import ReactGA from 'react-ga';

import Layout from '../../components/Layout/Layout';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import RampTopBar from '../../components/OnRampComponents/RampTopBar/RampTopBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Plus, BlackDot, offRampActiveIcon, greyChevron } from '../../assets';
import { connect, useSelector } from 'react-redux';
import { _GET_SAVED_BANK } from '../../redux/transaction/transactionActions';

interface Props {
  _GET_SAVED_BANK: (email: string) => void;
}

const SavedBanks: FC<Props> = ({ _GET_SAVED_BANK }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const transactionData = location.state?.data;
  const email: any = location.state?.email;
  const getSavedBanks = useSelector(
    (state: any) => state?.transaction?.getSavedBanks
  );

  const bankList = getSavedBanks.length > 0 ? getSavedBanks : [];

  useEffect(() => {
    _GET_SAVED_BANK(email);
  }, [_GET_SAVED_BANK, email]);

  return (
    <Layout>
      <ScrollToTop>
        <div className="ramp-main">
          <RampTopBar
            icon={offRampActiveIcon}
            rampType="off"
            receivingBank={true}
            url="/off-ramp"
            data={{ data: transactionData, stage: 4, loading: false }}
            title={'Select receiving bank account'}
          />
        </div>

        <div
          className="add-bank-container"
          onClick={() => {
            navigate('/receiving-bank', { state: { ...transactionData } });
            ReactGA.event({
              category: 'add bank',
              action: 'add bank button click',
              label: 'add_bank',
            });
          }}
        >
          <div className="img-container">
            <img src={Plus} alt="add-icon" />
          </div>
          <p>Add bank account</p>
        </div>

        <div className="banks-list">
          {bankList?.map((bank: any, index: number) => (
            <div
              key={index}
              className="bank"
              onClick={() => {
                navigate('/confirm-transaction', {
                  state: {
                    data: { ...transactionData, ...bank },
                    backUrl: '/saved-banks',
                    rampType: 'off',
                  },
                });

                ReactGA.event({
                  category: 'select bank',
                  action: 'select bank button click',
                  label: 'select_bank',
                });
              }}
            >
              <div className="left-col">
                <div className="top-details">
                  <p>{bank.account_number}</p>
                  <span>
                    <img src={BlackDot} alt="" />
                  </span>
                  <p>{bank.bank_name}</p>
                </div>
                <p className="name">{bank.account_name}</p>
              </div>
              <div className="chevron-container">
                <img src={greyChevron} alt="" />
              </div>
            </div>
          ))}
        </div>
      </ScrollToTop>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  _GET_SAVED_BANK: (email: string) => dispatch(_GET_SAVED_BANK(email)),
});

export default connect(null, mapDispatchToProps)(SavedBanks);
