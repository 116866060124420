import React, { type FC } from 'react';
import { LightBtnLoader } from '../../assets';

interface Props {
  isLoading: boolean;
}

const PendingState: FC<Props> = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div className="pending-state">
      <div className="inner">
        <img src={LightBtnLoader} alt="btn" style={{ width: 32 }} />
        <p>Transaction loading...</p>
      </div>
    </div>
  );
};

export default PendingState;
