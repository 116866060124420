export const INITIATE_TRANSACTION = 'INITIATE_TRANSACTION';
export const INITIATE_TRANSACTION_ERROR = 'INITIATE_TRANSACTION_ERROR';

export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_EMAIL_ERROR = 'VALIDATE_EMAIL_ERROR';

export const VALIDATE_EMAIL_OTP = 'VALIDATE_EMAIL_OTP';
export const VALIDATE_EMAIL_OTP_ERROR = 'VALIDATE_EMAIL_OTP_ERROR';

export const PROCESS_KYC = 'PROCESS_KYC';
export const PROCESS_KYC_ERROR = 'PROCESS_KYC_ERROR';
export const GET_RATE = 'GET_RATE';
export const GET_SUPPORTED_TOKENS = 'GET_SUPPORTED_TOKENS';
export const GET_BANK_LIST = 'GET_BANK_LIST';

export const RESOLVE_BANK = 'RESOLVE_BANK';
export const RESOLVE_BANK_ERROR = 'RESOLVE_BANK_ERROR';

export const ADD_BANK = 'ADD_BANK';
export const ADD_BANK_ERROR = 'ADD_BANK_ERROR';

export const GET_SAVED_BANKS = 'GET_SAVED_BANKS';

export const ON_RAMP = 'ON_RAMP';
export const ON_AND_OFF_RAMP_ERROR = 'ON_AND_OFF_RAMP_ERROR';
export const OFF_RAMP = 'OFF_RAMP';
export const VALIDATE_WALLET_ADDRESS = 'VALIDATE_WALLET_ADDRESS';
export const VALIDATE_WALLET_ADDRESS_ERROR = 'VALIDATE_WALLET_ADDRESS_ERROR';

export const KYC_TIER_THREE = 'KYC_TIER_THREE';
export const KYC_TIER_THREE_ERROR = 'KYC_TIER_THREE_ERROR';

export const KYC_TIER_ONE = 'KYC_TIER_ONE';
export const KYC_TIER_ONE_ERROR = 'KYC_TIER_ONE_ERROR';

export const KYC_TIER_TWO = 'KYC_TIER_TWO';
export const KYC_TIER_TWO_ERROR = 'KYC_TIER_TWO_ERROR';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';

export const BASIC_INFORMATION = 'BASIC_INFORMATION';
export const BASIC_INFORMATION_ERROR = 'BASIC_INFORMATION_ERROR';

export const GET_SUPPORTED_FIAT = 'GET_SUPPORTED_FIAT';
export const GET_SUPPORTED_ID = 'GET_SUPPORTED_ID';
export const GET_SUPPORTED_COUNTRIES = 'GET_SUPPORTED_COUNTRIES';

export const GET_COUNTRY_WITH_STATE = 'GET_COUNTRY_WITH_STATE';
export const GET_LOCAL_GOVERNMENT = 'GET_LOCAL_GOVERNMENT';

export const SOLANA_OFF_RAMP = 'SOLANA_OFF_RAMP';
export const VERIFY_SOLANA_OFF_RAMP = 'VERIFY_SOLANA_OFF_RAMP';
