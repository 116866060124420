import React from 'react';
import TransactionResponse from '../../components/TransactionResponse/TransactionResponse';
import { failedBg, failedIcon } from '../../assets';
import Layout from '../../components/Layout/Layout';

const ErrorFallback = ({ error, resetErrorBoundary }: any) => (
  <Layout>
    <TransactionResponse
      status={'500'}
      iconBg={failedBg}
      icon={failedIcon}
      rampType={'off'}
      buttonTitle="Back to home"
      titleStyle={{ width: '260px', marginTop: '-20px' }}
      transactionData={{}}
      descriptionStyle={{ width: '260px' }}
      statusText={'Oops! An error Fallback has occurred'}
      description={
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        error
          ? error.message
          : 'We could not process your request or find the page you are looking for at this time. Please check back later or go back to the home page.'
      }
      buttonAction={resetErrorBoundary}
    />
  </Layout>
);

export default ErrorFallback;
