import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';
import InvalidEmail from './pages/InvalidEmail/InvalidEmail';
import LandingPage from './pages/LandingPage/LandingPage';
import OffRamp from './pages/OffRamp/OffRamp';
import ConfirmTransaction from './pages/ConfirmTransaction/ConfirmTransaction';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import TransferInfo from './pages/TransferInfo/TransferInfo';
import TransactionStatus from './pages/TransactionStatus/TransactionStatus';
import OnRamp from './pages/OnRamp/OnRamp';
import ReceivingBank from './pages/ReceivingBank/ReceivingBank';
import ConfirmAccount from './pages/ConfirmAccount/ConfirmAccount';
import SendCrypto from './pages/SendCrypto/SendCrypto';
import { Provider } from 'react-redux';
import store from './redux/store';
import LiveTest from './pages/LiveTest/LiveTest';
import PhoneVerification from './pages/PhoneVerification/PhoneVerification';
import Tier2Verification from './pages/Tier2Verification/Tier2Verification';
import VerifyOtp from './pages/VerifyOtp/VerifyOtp';
import Tier1Verification from './pages/Tier1Verification/Tier1Verification';
import VerifyEmailOtp from './pages/VerifyEmailOtp/VerifyEmailOtp';
import Tier3Verification from './pages/Tier3Verification/Tier3Verification';
import SavedBanks from './pages/SavedBanks/SavedBanks';
import { useEffect } from 'react';
import Error from './pages/ErrorFallback';
import AppSolanaPay from './pages/AppSolanaPay/AppSolanaPay';
import StablePay from './pages/StablePay/StablePay';
require('@solana/wallet-adapter-react-ui/styles.css');
// eslint-disable-next-line import/first
import './scss/main.scss';

function App(): any {
  useEffect(() => {
    const TRACKING_ID: any = process.env.REACT_APP_MEASUREMENT_ID;
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<LandingPage />} />
          <Route path="on-ramp" element={<OnRamp />} />
          <Route path="off-ramp" element={<OffRamp />} />
          <Route path="confirm-transaction" element={<ConfirmTransaction />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="confirm-email-otp" element={<VerifyEmailOtp />} />
          <Route path="error-email" element={<InvalidEmail />} />
          <Route path="transfer" element={<TransferInfo />} />
          <Route path="tier1-verification" element={<Tier1Verification />} />
          <Route path="phone-verification" element={<PhoneVerification />} />
          <Route path="confirm-otp" element={<VerifyOtp />} />
          <Route path="tier2-verification" element={<Tier2Verification />} />
          <Route path="tier3-verification" element={<Tier3Verification />} />
          <Route path="live-test" element={<LiveTest />} />
          <Route path="transaction-status" element={<TransactionStatus />} />
          <Route path="receiving-bank" element={<ReceivingBank />} />
          <Route path="saved-banks" element={<SavedBanks />} />
          <Route path="confirm-account" element={<ConfirmAccount />} />
          <Route path="send-crypto" element={<SendCrypto />} />
          <Route path="app-link" element={<AppSolanaPay />} />
          <Route path="stablepay" element={<StablePay />} />

          <Route path="500" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
