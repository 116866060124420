import { useState } from 'react';
import type { FC } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { copy } from '../../assets';

interface CopyComponentProps {
  icon: string;
  text: string;
  position?: string;
}

const CopyComponent: FC<CopyComponentProps> = ({ icon, text, position }) => {
  const [click, setClick] = useState<boolean>(false);

  const handleClick = () => {
    setClick(true);
    setTimeout(() => {
      setClick(false);
    }, 3000);
  };

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        handleClick();
      }}
    >
      {click ? (
        <span className="copied-style">
          <img src={icon} alt="check-icon" />
          <p className="copied-text">Copied</p>
        </span>
      ) : (
        <span className="copy-icon">
          <img
            width={position === 'lower' ? 24 : 18}
            height={position === 'lower' ? 24 : 18}
            src={copy}
            alt="copy-icon"
          />
        </span>
      )}
    </CopyToClipboard>
  );
};

export default CopyComponent;
