import { useState, type FC } from 'react';
import Layout from '../../components/Layout/Layout';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import RampTopBar from '../../components/OnRampComponents/RampTopBar/RampTopBar';
import { dot, offRampActiveIcon, errorIcon } from '../../assets';
import { useLocation, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { _ADD_BANK } from '../../redux/transaction/transactionActions';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import ErrorField from '../../components/Fields/ErrorField/ErrorField';

const ConfirmAccount: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const transactionData = location.state?.data;
  const dispatch: any = useDispatch();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { bank_code, account_number } = transactionData;
  const email: any = localStorage.getItem('email');
  const [activeButton, setActiveButton] = useState<boolean>(true);

  const addBankError = useSelector(
    (state: any) => state.transaction.addBankError
  );

  console.log('🚀 ~ addBankError:', addBankError);

  return (
    <Layout>
      <ScrollToTop>
        <div className="ramp-main">
          <RampTopBar
            icon={offRampActiveIcon}
            rampType="off"
            receivingBank={true}
            url="/receiving-bank"
            data={transactionData}
            title={'Add receiving bank account'}
          />

          <div className="confirm-account-container">
            <p className="confirm-account-text">Is this your bank account?</p>

            <div className="user-icon-bg">
              <p>{transactionData?.account_name.slice(0, 1)}</p>
            </div>

            <p className="name">{transactionData?.account_name}</p>
            <p className="account-details">
              {transactionData?.bank}{' '}
              <span>
                <img src={dot} alt="" />
              </span>
              {transactionData?.accountNumber}
            </p>
          </div>

          <div className="button-container">
            <PrimaryButton
              validForm={true}
              type={'button'}
              title={'No, this isn’t mine'}
              style={{ background: ' #ECEFF1', color: '#191C1F' }}
              onClick={() => {
                navigate('/receiving-bank', {
                  state: { ...transactionData },
                });
              }}
            />
            <PrimaryButton
              validForm={true}
              type={'button'}
              title={'Yes, this is my account'}
              style={{ marginTop: '12px' }}
              activeButton={activeButton}
              onClick={() => {
                setActiveButton(false);
                dispatch(
                  _ADD_BANK(
                    email,
                    bank_code,
                    account_number,
                    transactionData,
                    navigate,
                    setActiveButton
                  )
                );
                ReactGA.event({
                  category: 'confirm bank details',
                  action: 'confirm bank details button',
                  label: 'confirm_bank_details',
                });
              }}
            />
          </div>
        </div>
      </ScrollToTop>
      {(addBankError !== null || addBankError !== null) && (
        <ErrorField
          icon={errorIcon}
          errorMessage={addBankError !== null ? addBankError : addBankError}
        />
        // <FieldStatus
        //   icon={errorIcon}
        //   mode={'error'}
        //   rampType="on"
        //   errorMessage={addBankError !== null ? addBankError : addBankError}
        // />
      )}
    </Layout>
  );
};

export default ConfirmAccount;
