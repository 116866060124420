import { authMiddleware } from './authMiddleware';

export const config = ({ path, method, body, date }: any): any => {
  const auth = authMiddleware({ path, method, body, date });

  return {
    headers: {
      Authorization: `Bearer ${auth}`,
      'SC-Timestamp': date,
      'Content-Type': 'application/json',
    },
  };
};

export const configWithSession = ({ path, method, body, date }: any): any => {
  const auth = authMiddleware({ path, method, body, date });
  const session = localStorage.getItem('session');

  return {
    headers: {
      Authorization: `Bearer ${auth}`,
      'SC-Timestamp': date,
      'Content-Type': 'application/json',
      session,
    },
  };
};

export const configWithSessionFile = ({
  path,
  method,
  // eslint-disable-next-line autofix/no-unused-vars
  body,
  date,
}: any): any => {
  const auth = authMiddleware({ path, method, body: {}, date });
  const session = localStorage.getItem('session');

  return {
    headers: {
      Authorization: `Bearer ${auth}`,
      'SC-Timestamp': date,
      session,
    },
  };
};
