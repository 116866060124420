import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  _KYC_TIER_ONE,
  _VALIDATE_EMAIL,
} from '../../redux/transaction/transactionActions';

interface Props {
  isEmail?: boolean;
  basicData?: object;
  setActiveButton?: any;
}

const ResendTimer: FC<Props> = ({ isEmail, basicData, setActiveButton }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [isCountdownOver, setIsCountdownOver] = useState<boolean>(false);
  const [countdownDate, setCountdownDate] = useState(new Date());
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  const data = location.state?.data;
  const phoneNumber = location.state?.phone_number;
  const country = location.state?.country;
  const rampType = location.state?.rampType;
  const email = location.state?.email;
  const goToTier = location.state?.goToTier;

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const difference = countdownDate.getTime() - currentTime.getTime();

      if (difference <= 0) {
        clearInterval(interval);
        setSecondsRemaining(0);
        setIsCountdownOver(true);
      } else {
        setIsCountdownOver(false);
        const seconds = Math.floor(difference / 1000);
        setSecondsRemaining(seconds);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdownDate]);

  useEffect(() => {
    const twoMinute = 120 * 1000;
    const currentTim = new Date();
    const countdownDat = new Date(currentTim.getTime() + twoMinute);
    setCountdownDate(countdownDat);
  }, []);

  const resendCode = () => {
    if (isEmail !== undefined && isEmail) {
      _VALIDATE_EMAIL(email, rampType, data, navigate, setActiveButton);
    } else {
      dispatch(
        _KYC_TIER_ONE(
          data,
          basicData,
          email,
          phoneNumber,
          country,
          rampType,
          goToTier,
          navigate
        )
      );
    }
    setIsCountdownOver(false);
    const oneMinute = 120 * 1000;
    const currentTim = new Date();
    const countdownDat = new Date(currentTim.getTime() + oneMinute);
    setCountdownDate(countdownDat);
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div className="resend-code">
      <div className="code-time">
        <span className={`${isCountdownOver ? 'timer-out' : ''}`}>
          {formatTime(secondsRemaining)}
        </span>
      </div>
      {isCountdownOver && (
        <p onClick={resendCode} className="resend">
          Resend code
        </p>
      )}
    </div>
  );
};

export default ResendTimer;
