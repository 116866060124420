import type { TransactionAction, TransactionState } from '../../../types';
import {
  KYC_TIER_THREE,
  KYC_TIER_THREE_ERROR,
  GET_BANK_LIST,
  GET_RATE,
  GET_SUPPORTED_COUNTRIES,
  GET_SUPPORTED_FIAT,
  GET_SUPPORTED_ID,
  GET_SUPPORTED_TOKENS,
  INITIATE_TRANSACTION,
  INITIATE_TRANSACTION_ERROR,
  KYC_TIER_ONE,
  KYC_TIER_ONE_ERROR,
  KYC_TIER_TWO,
  KYC_TIER_TWO_ERROR,
  OFF_RAMP,
  ON_AND_OFF_RAMP_ERROR,
  ON_RAMP,
  PROCESS_KYC,
  PROCESS_KYC_ERROR,
  RESOLVE_BANK,
  RESOLVE_BANK_ERROR,
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_ERROR,
  VALIDATE_WALLET_ADDRESS,
  VALIDATE_WALLET_ADDRESS_ERROR,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  BASIC_INFORMATION,
  BASIC_INFORMATION_ERROR,
  VALIDATE_EMAIL_OTP,
  VALIDATE_EMAIL_OTP_ERROR,
  GET_COUNTRY_WITH_STATE,
  GET_LOCAL_GOVERNMENT,
  ADD_BANK,
  ADD_BANK_ERROR,
  GET_SAVED_BANKS,
  SOLANA_OFF_RAMP,
  VERIFY_SOLANA_OFF_RAMP,
} from './types';

const initialState: TransactionState = {
  initiateTransaction: {},
  initiateTransactionError: null,
  validateEmail: {},
  validateEmailOTP: {},
  validateEmailOTPError: null,
  validateEmailError: null,
  processKYC: {},
  processKYCError: null,
  getRates: {},
  supportedToken: [],
  bankList: [],
  resolveBank: {},
  resolveBankError: null,
  onRamp: {},
  offRamp: {},
  validateAddress: {},
  validateAddressError: null,
  kycTierThree: {},
  kycTierThreeError: null,
  kycTierOne: {},
  kycTierOneError: null,
  kycTierTwo: {},
  kycTierTwoError: null,
  basicInformation: {},
  basicInformationError: null,
  verifyOTP: {},
  verifyOTPError: null,
  onAndOffRampError: null,
  supportedFiat: [],
  supportedID: [],
  supportedCountries: [],
  countryWithState: {},
  solanaOffRamp: {},
  verifySolanaOffRamp: {},
  localGov: {},
  loading: false,
  addBankError: null,
  getSavedBanks: {},
  error: null,
};

const transactionReducer = (
  state = initialState,
  action: TransactionAction
) => {
  const { type, payload } = action;

  switch (type) {
    case INITIATE_TRANSACTION:
      return {
        ...state,
        initialTransaction: payload,
        loading: true,
      };
    case VALIDATE_EMAIL:
      return {
        ...state,
        validateEmail: payload,
        loading: true,
      };
    case VALIDATE_EMAIL_OTP:
      return {
        ...state,
        validateEmailOTP: payload,
        loading: true,
      };
    case PROCESS_KYC:
      return {
        ...state,
        processKYC: payload,
        loading: true,
      };
    case PROCESS_KYC_ERROR:
      return {
        ...state,
        processKYCError: payload,
        loading: true,
      };
    case GET_RATE:
      return {
        ...state,
        getRates: payload,
        loading: true,
      };
    case SOLANA_OFF_RAMP:
      return {
        ...state,
        solanaOffRamp: payload,
        loading: true,
      };
    case VERIFY_SOLANA_OFF_RAMP:
      return {
        ...state,
        verifySolanaOffRamp: payload,
        loading: true,
      };
    case GET_SUPPORTED_TOKENS:
      return {
        ...state,
        supportedToken: payload,
        loading: true,
      };
    case GET_SUPPORTED_COUNTRIES:
      return {
        ...state,
        supportedCountries: payload,
        loading: true,
      };
    case GET_COUNTRY_WITH_STATE:
      return {
        ...state,
        countryWithState: payload,
        loading: true,
      };
    case GET_BANK_LIST:
      return {
        ...state,
        bankList: payload,
        loading: true,
      };
    case RESOLVE_BANK:
      return {
        ...state,
        resolveBank: payload,
        loading: true,
      };
    case ON_RAMP:
      return {
        ...state,
        onRamp: payload,
        loading: true,
      };
    case OFF_RAMP:
      return {
        ...state,
        offRamp: payload,
        loading: true,
      };
    case VALIDATE_WALLET_ADDRESS:
      return {
        ...state,
        validateAddress: payload,
        loading: true,
      };
    case KYC_TIER_THREE:
      return {
        ...state,
        kycTierThree: payload,
        loading: true,
      };
    case KYC_TIER_THREE_ERROR:
      return {
        ...state,
        kycTierThreeError: payload,
        loading: true,
      };
    case KYC_TIER_ONE:
      return {
        ...state,
        kycTierOne: payload,
        loading: true,
      };
    case KYC_TIER_ONE_ERROR:
      return {
        ...state,
        kycTierOneError: payload,
        loading: true,
      };
    case KYC_TIER_TWO:
      return {
        ...state,
        kycTierTwo: payload,
        loading: true,
      };
    case KYC_TIER_TWO_ERROR:
      return {
        ...state,
        kycTierTwoError: payload,
        loading: true,
      };
    case BASIC_INFORMATION:
      return {
        ...state,
        basicInformation: payload,
        loading: true,
      };
    case BASIC_INFORMATION_ERROR:
      return {
        ...state,
        basicInformationError: payload,
        loading: true,
      };
    case VERIFY_OTP:
      return {
        ...state,
        verifyOTP: payload,
        loading: true,
      };
    case VERIFY_OTP_ERROR:
      return {
        ...state,
        kycTierOneError: payload,
        loading: true,
      };
    case VALIDATE_WALLET_ADDRESS_ERROR:
      return {
        ...state,
        verifyOTPError: payload,
        loading: true,
      };
    case GET_SUPPORTED_FIAT:
      return {
        ...state,
        supportedFiat: payload,
        loading: true,
      };
    case GET_SUPPORTED_ID:
      return {
        ...state,
        supportedID: payload,
        loading: true,
      };
    case RESOLVE_BANK_ERROR:
      return {
        ...state,
        resolveBankError: payload,
        loading: true,
      };
    case VALIDATE_EMAIL_ERROR:
      return {
        ...state,
        validateEmailError: payload,
        loading: true,
      };
    case GET_LOCAL_GOVERNMENT:
      return {
        ...state,
        localGov: payload,
        loading: true,
      };
    case VALIDATE_EMAIL_OTP_ERROR:
      return {
        ...state,
        validateEmailOTPError: payload,
        loading: true,
      };
    case INITIATE_TRANSACTION_ERROR:
      return {
        ...state,
        initiateTransactionError: payload,
        loading: true,
      };
    case ON_AND_OFF_RAMP_ERROR:
      return {
        ...state,
        onAndOffRampError: payload,
        loading: true,
      };
    case GET_SAVED_BANKS:
      return {
        ...state,
        getSavedBanks: payload,
        loading: true,
      };
    case ADD_BANK_ERROR:
      return {
        ...state,
        addBankError: payload,
        loading: true,
      };
    case ADD_BANK:
    default:
      return state;
  }
};

export default transactionReducer;
