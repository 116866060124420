/* eslint-disable autofix/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useState, type FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import Layout from '../../components/Layout/Layout';
import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import TopPageTransactionInfo from '../../components/TopPageTransactionInfo/TopPageTransactionInfo';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { errorIcon, logo, logoBg } from '../../assets';
import PageLogo from '../../components/PageLogo/PageLogo';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { createStructuredSelector } from 'reselect';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import InputField from '../../components/Fields/InputField/InputField';
import {
  selectKYCTierTwo,
  selectKYCTierTwoError,
  selectSupportedCountries,
  selectSupportedID,
} from '../../redux/transaction/transactionSelectors';
import { connect, useSelector } from 'react-redux';
import {
  _GET_SUPPORTED_COUNTRIES,
  _GET_SUPPORTED_ID,
  // _KYC_TIER_TWO,
} from '../../redux/transaction/transactionActions';
import type {
  IProcessKYC,
  ISupportedCountriesData,
  KYCTierOne,
} from '../../../types';
import FieldStatus from '../../components/Fields/FieldStatus/FieldStatus';
import Select from '../../components/Fields/Select/Select';
import DateField from '../../components/Fields/DateField/DateField';

interface Props {
  _GET_SUPPORTED_ID: () => void;
  _GET_SUPPORTED_COUNTRIES: () => void;
  kycTierTwo: KYCTierOne;
  kycTierTwoError: any;
  supportedID: any;
  supportedCountries: ISupportedCountriesData[];
}

const Tier2Verification: FC<Props> = ({
  kycTierTwoError,
  supportedID,
  _GET_SUPPORTED_ID,
  _GET_SUPPORTED_COUNTRIES,
  supportedCountries,
}) => {
  const location = useLocation();
  const navigate: any = useNavigate();
  const data = location.state?.data;
  const rampType = location.state?.rampType;
  const goToTier = location.state?.goToTier;
  const email: any = localStorage.getItem('email');

  const [form, setForm] = useState<boolean>(false);
  const [, setIdDetails] = useState<object>();

  const [checking, setChecking] = useState<boolean>(true);
  const [activeButton, setActiveButton] = useState<boolean>(true);

  const onAndOffRampError = useSelector(
    (state: any) => state.transaction.onAndOffRampError
  );

  const text =
    !checking && form
      ? 'Fill the form to complete your identity verification'
      : !form && !checking
      ? 'Identity verification'
      : 'Ramp is checking if your identity is validated';

  useEffect(() => {
    _GET_SUPPORTED_ID();
    _GET_SUPPORTED_COUNTRIES();
    setTimeout(() => {
      setChecking(false);
      setForm(true);
    }, 2000);
  }, [_GET_SUPPORTED_ID, _GET_SUPPORTED_COUNTRIES]);

  return (
    <Layout>
      <ScrollToTop>
        <TopPageTransactionInfo
          title={'PAYING'}
          rampType={rampType}
          amount={
            rampType === 'on'
              ? parseFloat(data?.currencyAmount).toLocaleString()
              : parseFloat(data?.amount)
                  .toFixed(data.network.toLocaleLowerCase() === 'btc' ? 8 : 3)
                  .toLocaleString()
          }
          token={rampType === 'on' ? data?.currency : data?.token}
        />

        <div className="top-welcome-page-content">
          <PageLogo iconBg={logoBg} icon={logo} />
          <h1 className="welcome-message">{text}</h1>
          {checking && <p>Kindly wait for a few seconds</p>}
          {checking && <LoadingComponent />}
        </div>

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            country: '',
            dob: '',
            idType: '',
            idNumber: '',
          }}
          validationSchema={Yup.object({
            country: Yup.string().required('This is a required field'),
            dob: Yup.string().required('This is a required field'),
            idType: Yup.string().required('This is a required field'),
            idNumber: Yup.string()
              .min(5, 'Invalid. Try again')
              .required('This is a required field'),
          })}
          onSubmit={(values, { setErrors }) => {
            const { country, dob, idNumber, idType } = values;
            const errors = {};
            if (
              Object.entries(errors).length === 0 &&
              errors.constructor === Object
            ) {
              setActiveButton(false);
              navigate('/live-test', {
                state: {
                  data,
                  country,
                  rampType,
                  goToTier,
                  dob,
                  idNumber,
                  idType,
                  email,
                },
              });
              ReactGA.event({
                category: 'live-test',
                action: 'got to live-test page button click',
                label: 'live_test',
              });
            } else {
              setErrors(errors);
            }
          }}
        >
          {({ handleSubmit, isValid, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              {!checking && (
                <div className="verify-email-content">
                  <div className="bvn-wrappe">
                    <div style={{ marginBottom: 16 }}>
                      <DateField
                        name={'dob'}
                        placeHolder={'Date of birth'}
                        setFieldValue={(value: any) => {
                          setFieldValue('dob', value);
                        }}
                        reInitValue={''}
                      />
                    </div>

                    <div style={{ marginBottom: 16 }}>
                      <Select
                        title={'Country'}
                        data={supportedCountries}
                        setFieldValue={setFieldValue}
                        name={'country'}
                        reInitValue={undefined}
                      />
                    </div>

                    <div style={{ marginBottom: 16 }}>
                      <Select
                        title={'Select ID type'}
                        data={supportedID}
                        setFieldValue={setFieldValue}
                        name={'idType'}
                        setIdDetails={setIdDetails}
                        className="data-modal-id"
                        reInitValue={undefined}
                      />
                    </div>

                    <InputField name="idNumber" placeHolder="Enter ID number" />

                    <PrimaryButton
                      title="Verify"
                      type="submit"
                      validForm={isValid}
                      onClick={() => {}}
                      activeButton={activeButton}
                      style={{ maxWidth: 335 }}
                    />

                    {(kycTierTwoError !== null ||
                      onAndOffRampError !== null) && (
                      <FieldStatus
                        icon={errorIcon}
                        mode={'error'}
                        errorMessage={
                          onAndOffRampError !== null
                            ? onAndOffRampError
                            : kycTierTwoError
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </ScrollToTop>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  supportedID: selectSupportedID,
  supportedCountries: selectSupportedCountries,
  kycTierTwo: selectKYCTierTwo,
  kycTierTwoError: selectKYCTierTwoError,
});

const mapDispatchToProps = (dispatch: any) => ({
  _GET_SUPPORTED_ID: () => dispatch(_GET_SUPPORTED_ID()),
  _GET_SUPPORTED_COUNTRIES: () => dispatch(_GET_SUPPORTED_COUNTRIES()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tier2Verification);
