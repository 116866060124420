import { createSelector } from 'reselect';

const selectTransaction = (state: any) => state.transaction;

export const selectTransactionLoading = createSelector(
  [selectTransaction],
  (transaction) => transaction.loading
);

export const selectTransactionRates = createSelector(
  [selectTransaction],
  (transaction) => transaction.getRates
);

export const selectSupportedTokens = createSelector(
  [selectTransaction],
  (transaction) => transaction.supportedToken
);

export const selectBankList = createSelector(
  [selectTransaction],
  (transaction) => transaction.bankList
);

export const selectResolveBank = createSelector(
  [selectTransaction],
  (transaction) => transaction.resolveBank
);

export const selectResolveBankError = createSelector(
  [selectTransaction],
  (transaction) => transaction.resolveBankError
);

export const selectOnRamp = createSelector(
  [selectTransaction],
  (transaction) => transaction.onRamp
);

export const selectOffRamp = createSelector(
  [selectTransaction],
  (transaction) => transaction.offRamp
);

export const selectProcessKYCError = createSelector(
  [selectTransaction],
  (transaction) => transaction.processKYCError
);

export const selectValidateWalletAddress = createSelector(
  [selectTransaction],
  (transaction) => transaction.validateAddress
);

export const selectkycTierThree = createSelector(
  [selectTransaction],
  (transaction) => transaction.kycTierThree
);

export const selectKYCTierOne = createSelector(
  [selectTransaction],
  (transaction) => transaction.kycTierOne
);

export const selectKYCTierOneError = createSelector(
  [selectTransaction],
  (transaction) => transaction.kycTierOneError
);

export const selectKYCTierTwo = createSelector(
  [selectTransaction],
  (transaction) => transaction.kycTierTwo
);

export const selectKYCTierTwoError = createSelector(
  [selectTransaction],
  (transaction) => transaction.kycTierTwoError
);

export const selectKYCTierThreeError = createSelector(
  [selectTransaction],
  (transaction) => transaction.kycTierThreeError
);

export const selectVerifyOTPError = createSelector(
  [selectTransaction],
  (transaction) => transaction.verifyOTPError
);

export const selectVerifyEmailOTPError = createSelector(
  [selectTransaction],
  (transaction) => transaction.validateEmailOTPError
);

export const selectSupportedFiat = createSelector(
  [selectTransaction],
  (transaction) => transaction.supportedFiat
);

export const selectSupportedID = createSelector(
  [selectTransaction],
  (transaction) => transaction.supportedID
);

export const selectOnAndOffRampError = createSelector(
  [selectTransaction],
  (transaction) => transaction.onAndOffRampError
);

export const selectSupportedCountries = createSelector(
  [selectTransaction],
  (transaction) => transaction.supportedCountries
);

export const selectBasicInformation = createSelector(
  [selectTransaction],
  (transaction) => transaction.basicInformation
);

export const selectBasicInformationError = createSelector(
  [selectTransaction],
  (transaction) => transaction.basicInformationError
);

export const selectCountriesWithState = createSelector(
  [selectTransaction],
  (transaction) => transaction.countryWithState
);

export const selectLocalGov = createSelector(
  [selectTransaction],
  (transaction) => transaction.localGov
);

export const selectSolanaOffRamp = createSelector(
  [selectTransaction],
  (transaction) => transaction.solanaOffRamp
);

export const selectVerifySolanaOffRamp = createSelector(
  [selectTransaction],
  (transaction) => transaction.verifySolanaOffRamp
);
