import type { FC } from 'react';
import { activeIcon, offRampActiveIcon } from '../../assets';
import ScrollToTop from '../ScrollToTopComponent/ScrollToTop';

interface TopPageTransactionInfoProps {
  title: string;
  amount: string;
  token: string;
  rampType: 'on' | 'off';
}

const TopPageTransactionInfo: FC<TopPageTransactionInfoProps> = ({
  title,
  token,
  amount,
  rampType,
}) => (
  <ScrollToTop>
    <div className="top-bar">
      <img src={rampType === 'on' ? activeIcon : offRampActiveIcon} alt="" />
      <p>
        {title}
        <span>
          {amount} {token?.toUpperCase()}
        </span>
      </p>
    </div>
  </ScrollToTop>
);

export default TopPageTransactionInfo;
