import { Fragment } from 'react';
import type { FC } from 'react';

interface FieldStatusProps {
  icon: string;
  fee?: string;
  total?: string;
  token?: string;
  rampType?: 'on' | 'off';
  errorMessage?: string;
  mode: 'error' | 'success';
}

const FieldStatus: FC<FieldStatusProps> = ({
  icon,
  mode,
  fee,
  total,
  token,
  rampType,
  errorMessage,
}) => (
  <Fragment>
    {mode === 'success' ? (
      <div
        style={
          rampType !== undefined && rampType === 'off'
            ? { background: 'rgba(151, 71, 255, 0.1)' }
            : { background: '' }
        }
        className="fee-price-wrapper"
      >
        <div className="fee-container">
          <img src={icon} alt="fee-icon" />
          <p
            style={
              rampType !== undefined && rampType === 'off'
                ? { color: '#946CFF' }
                : { color: '' }
            }
          >
            Fee <span>{fee} </span>
          </p>
        </div>
        <p className="total">
          TOTAL:{' '}
          <span>
            {total} {token}
          </span>
        </p>
      </div>
    ) : (
      <div
        className={`fee-price-wrapper  ${
          mode === 'error' ? 'error-fee-price-wrapper' : ''
        } `}
      >
        <div className="fee-container">
          <img src={icon} alt="fee-icon" />
          <p className="error-message">{errorMessage}</p>
        </div>
      </div>
    )}
  </Fragment>
);

export default FieldStatus;
