/* eslint-disable autofix/no-unused-vars */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef, useState } from 'react';
import '@smile_identity/smart-camera-web';
import type { IProcessKYC } from '../../../types';
import {
  _GET_SAVED_BANK,
  _KYC_TIER_TWO,
} from '../../redux/transaction/transactionActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import TransactionResponse from '../../components/TransactionResponse/TransactionResponse';
import { failedBg, failedIcon } from '../../assets';
import { createStructuredSelector } from 'reselect';
import { selectKYCTierTwoError } from '../../redux/transaction/transactionSelectors';
import LoadingState from '../../components/LoadingState/LoadingState';
import isEmpty from 'lodash.isempty';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'smart-camera-web': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

interface Props {
  _KYC_TIER_TWO: (
    data: any,
    kycData: IProcessKYC,
    email: string,
    idType: string,
    idNumber: string,
    country: string,
    dob: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) => void;
  kycTierTwoError: any;
}

const LiveTest: React.FC<Props> = ({ _KYC_TIER_TWO, kycTierTwoError }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { email, data, idType, idNumber, country, dob, rampType, goToTier } =
    location.state;

  const onAndOffRampError = useSelector(
    (state: any) => state.transaction.onAndOffRampError
  );
  const [, setImageData] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const ref: any = useRef(null);
  const [errorState, setErrorState] = useState<boolean>(false);

  const savedBanks = useSelector(
    (state: any) => state.transaction.getSavedBanks
  );

  useEffect(() => {
    dispatch(_GET_SAVED_BANK(email));
  }, [dispatch, email]);

  useEffect(() => {
    if (onAndOffRampError !== null || !isEmpty(kycTierTwoError)) {
      setLoading(false);
      setErrorState(true);
    }
  }, [onAndOffRampError, kycTierTwoError]);

  useEffect(() => {
    const element = ref.current;
    element.addEventListener('imagesComputed', (e: any) => {
      setLoading(true);
      _KYC_TIER_TWO(
        data,
        e.detail,
        email,
        idType,
        idNumber,
        country,
        dob,
        rampType,
        goToTier,
        navigate,
        setLoading,
        savedBanks
      );
    });

    return () => {
      element.removeEventListener('imagesComputed', () => {
        setImageData(null);
      });
    };
  }, [
    email,
    idType,
    idNumber,
    country,
    dob,
    rampType,
    goToTier,
    navigate,
    setLoading,
    _KYC_TIER_TWO,
    data,
    savedBanks,
  ]);

  return (
    <Layout>
      {errorState && !loading && (
        <div className="kyc-error">
          <TransactionResponse
            status={'kyc_failed'}
            iconBg={failedBg}
            icon={failedIcon}
            rampType={rampType}
            buttonTitle={'Try again'}
            titleStyle={{ width: '260px', marginTop: '-20px' }}
            transactionData={data}
            descriptionStyle={{ width: '260px' }}
            statusText={
              onAndOffRampError !== null
                ? onAndOffRampError
                : kycTierTwoError.id_verification_result_text !== undefined
                ? kycTierTwoError.id_verification_result_text
                : kycTierTwoError.message
            }
            description={
              kycTierTwoError.basic_kyc_result_text !== undefined
                ? kycTierTwoError.basic_kyc_result_text
                : ''
            }
            buttonAction={() => {
              setErrorState(false);
              window.location.reload();
            }}
          />
        </div>
      )}

      {kycTierTwoError === null && loading && (
        <LoadingState title={'Verifying your ID'} />
      )}

      <div
        className={
          kycTierTwoError !== null || onAndOffRampError !== null || loading
            ? 'hidden'
            : 'capture-container'
        }
      >
        {idType === 'BVN' ? (
          <smart-camera-web
            ref={ref}
            document-capture-modes="camera"
          ></smart-camera-web>
        ) : (
          <smart-camera-web capture-id ref={ref}></smart-camera-web>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  kycTierTwoError: selectKYCTierTwoError,
});

const mapDispatchToProps = (dispatch: any) => ({
  _KYC_TIER_TWO: (
    data: any,
    kycData: IProcessKYC,
    email: string,
    idType: string,
    idNumber: string,
    country: string,
    dob: string,
    rampType: string,
    goToTier: string,
    navigate: any,
    setActiveButton: (v: boolean) => void,
    savedBanks: any
  ) =>
    dispatch(
      _KYC_TIER_TWO(
        data,
        kycData,
        email,
        idType,
        idNumber,
        country,
        dob,
        rampType,
        goToTier,
        navigate,
        setActiveButton,
        savedBanks
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveTest);
