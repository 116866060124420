/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState } from 'react';
import {
  amountSwitch,
  errorIcon,
  feeIcon,
  offRampFeeIcon,
} from '../../../../assets';
import FieldPosition from '../../FieldPosition/FieldPosition';
import type { FC } from 'react';
import FieldStatus from '../../FieldStatus/FieldStatus';
import AmountInputs from '../AmountInputs/AmountInputs';
import { useField } from 'formik';
import LoadingComponent from '../../../LoadingComponent/LoadingComponent';

export interface FieldProps {
  name: string;
  title: string;
  rate: number;
  toGet: string;
  crypto?: string;
  usdRate: number;
  currency?: string;
  isDisabled: boolean;
  lastStage?: boolean;
  currentStage: number;
  fieldPosition: number;
  amountField?: boolean;
  setCurrentStage?: any;
  rampType: 'on' | 'off';
  setFieldValue: any;
  percentageFee: number;
  gasFee?: number;
  selectedNetwork: string;
  totalFee: number;
}

const AmountField: FC<FieldProps> = ({
  currency,
  title,
  name,
  crypto,
  rate,
  lastStage,
  rampType,
  currentStage,
  fieldPosition,
  setCurrentStage,
  toGet,
  isDisabled,
  usdRate,
  amountField,
  setFieldValue,
  percentageFee,
  gasFee,
  selectedNetwork,
  totalFee,
}) => {
  const [field, meta] = useField(name);
  const [switchField, setSwitchField] = useState<boolean>(rampType !== 'on');

  // const gasFee = fixedGasFee(selectedNetwork);
  const rampFeeStructure = `${percentageFee}% + $${gasFee}`;

  console.log('🚀 ~ gasFee:', gasFee);
  console.log('🚀 ~ percentageFee:', percentageFee);

  const handleFieldSwitch = () => {
    setSwitchField((prev: boolean) => !prev);
  };

  return (
    <div className="select-field-container amount-input-container">
      <FieldPosition
        rampType={rampType}
        lastStage={lastStage}
        fieldPosition={fieldPosition}
        currentStage={currentStage}
      />

      <div
        className={`field-container ${
          currentStage === fieldPosition ? 'no-border' : ''
        } `}
      >
        <p
          className={`title ${
            currentStage < fieldPosition ? 'in-active-field' : ''
          }`}
        >
          {title}
        </p>

        {(currentStage === fieldPosition || currentStage > fieldPosition) && (
          <>
            <div>
              <div
                className={`inputs-container ${
                  currentStage === fieldPosition && meta.error === undefined
                    ? rampType !== undefined && rampType === 'off'
                      ? 'offRamp-success-field-border '
                      : 'onRamp-success-field-border'
                    : meta.touched && meta.error !== undefined
                    ? 'error-amount-input-border '
                    : ''
                }`}
              >
                <AmountInputs
                  usdRate={usdRate}
                  currency={currency}
                  crypto={crypto}
                  percentageFee={percentageFee}
                  rampType={rampType}
                  switchField={switchField}
                  setFieldValue={setFieldValue}
                  toGet={toGet}
                  rate={rate}
                  name={field.name}
                  title={''}
                  currentStage={0}
                  fieldPosition={0}
                  selectedNetwork={selectedNetwork}
                  isDisabled={isDisabled}
                  totalFee={totalFee}
                />

                <div
                  className="switch-container"
                  onClick={
                    isDisabled !== undefined ? () => null : handleFieldSwitch
                  }
                >
                  <img
                    className={switchField ? 'rotate' : 'chevron'}
                    src={amountSwitch}
                    alt=""
                  />
                </div>
              </div>

              {fieldPosition === currentStage && meta.error === undefined && (
                <FieldStatus
                  icon={
                    rampType !== undefined && rampType === 'off'
                      ? offRampFeeIcon
                      : feeIcon
                  }
                  mode={'success'}
                  fee={rampFeeStructure}
                  rampType={rampType}
                  total={Number(toGet)?.toLocaleString()}
                  token={
                    rampType === 'on' ? crypto : currency?.toLocaleUpperCase()
                  }
                />
              )}

              {fieldPosition === currentStage &&
                meta.touched &&
                meta.error !== undefined && (
                  <FieldStatus
                    icon={errorIcon}
                    mode={'error'}
                    errorMessage={meta.error}
                  />
                )}
            </div>

            {amountField === true && fieldPosition === currentStage && (
              <div className="price-next-button-container">
                <div className="price-container">
                  {rate !== undefined ? (
                    <div className="rate">
                      1 {crypto} = {rate.toLocaleString()}{' '}
                      {currency?.toLocaleUpperCase()}
                    </div>
                  ) : (
                    <div className="rate">
                      <> 1 {crypto} =</>
                      <LoadingComponent />
                    </div>
                  )}
                </div>

                {rampType !== 'off' ? (
                  <button
                    type="button"
                    disabled={meta.error !== undefined}
                    className={meta.error !== undefined ? '' : 'active-btn'}
                    onClick={() => {
                      setCurrentStage((prev: number) => prev + 1);
                    }}
                  >
                    <span>Next</span>
                  </button>
                ) : null}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AmountField;
