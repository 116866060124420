import { type FC, useEffect, useState } from 'react';

const PReceivedTimer: FC = () => {
  const storedTime: any = localStorage.getItem('paymentReceivedTime');
  const storedSeconds = parseInt(storedTime);
  const [seconds, setSeconds] = useState<number>(storedSeconds);

  useEffect(() => {
    localStorage.setItem('paymentReceivedTime', seconds.toString());

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0) {
      //   window.location.replace('/');
    }
  }, [seconds]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div style={{ textAlign: 'center', marginTop: 16 }}>
      <h1 style={{ fontSize: 14 }}>In {formatTime(seconds)}</h1>
    </div>
  );
};

export default PReceivedTimer;
