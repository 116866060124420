/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-return-assign */
import { ScalexLogo } from '../../assets';
import Layout from '../../components/Layout/Layout';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { useSearchParams } from 'react-router-dom';
import { getOperatingSystem } from '../../utils';

const StablePay = () => {
  const [params] = useSearchParams();

  const token: string | any | null = params.get('token');
  const network: string | any | null = params.get('network');
  const amount: string | any | null = params.get('amount');
  const address: string | any | null = params.get('address');
  const reference: string | any | null = params.get('reference');
  const source: string | any | null = params.get('source');

  const urlNetwork = network;
  const urlAddress = address;
  const urlAmount = amount;

  const OS = getOperatingSystem(window);

  const DownloadApp = () => {
    if (OS === 'MacOS') {
      return (window.location.href =
        'https://apps.apple.com/ng/app/scalex/id6444333275');
    } else
      return (window.location.href =
        'https://play.google.com/store/apps/details?id=com.scalex.scalex');
  };

  return (
    <Layout>
      <div className="stablepay">
        <img className="image" src={ScalexLogo} alt="" />

        <h3>Use the Scalex App to continue your transaction</h3>

        <PrimaryButton
          onClick={DownloadApp}
          validForm={true}
          type={'button'}
          title={'Download the Scalex App'}
          style={{
            background: ' #3385E2',
            color: '#fff',
          }}
        />

        <a
          href={`scalex://Home/${token}/${urlNetwork}/${urlAddress}/${urlAmount}/${reference}/${source}`}
          style={{
            textDecoration: 'none',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <PrimaryButton
            validForm={true}
            type={'button'}
            title={'I already have the app'}
            style={{
              background: ' #E4E7EC',
              color: '#1D2939',
            }}
          />
        </a>
      </div>
    </Layout>
  );
};
export default StablePay;
