import { useEffect, useState } from 'react';
import type { FC } from 'react';
import DatePicker from 'react-date-picker';

import { dateIcon } from '../../../assets';

interface DateFieldProps {
  check?: any;
  name: string;
  placeHolder: string;
  setFieldValue: any;
  reInitValue: string;
}

const DateField: FC<DateFieldProps> = ({
  check,
  placeHolder,
  setFieldValue,
}) => {
  const [isShown, setIsShown] = useState<boolean>(check !== undefined);
  const [value, setValue] = useState<any>(new Date());

  useEffect(() => {
    setFieldValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      className="date-container"
      onClick={() => {
        setIsShown(true);
      }}
    >
      <>
        {!isShown ? (
          <div className="date-false-container">
            <input
              className="input-field"
              placeholder={placeHolder}
              type="text"
            />
            <img src={dateIcon} alt="" />
          </div>
        ) : (
          <DatePicker
            className="date-field"
            onChange={setValue}
            value={value}
          />
        )}
      </>
    </div>
  );
};

export default DateField;
