/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import ReactGA from 'react-ga';

import ScrollToTop from '../../components/ScrollToTopComponent/ScrollToTop';
import {
  ErrorIconWithBg,
  landingPageBg,
  logo,
  offrampBtn,
  onrampBtn,
} from '../../assets';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { connect, useSelector } from 'react-redux';
import { _INITIATE_TRANSACTION } from '../../redux/transaction/transactionActions';

interface Props {
  _INITIATE_TRANSACTION: (
    navigate: any,
    redirectTo: string,
    type: string
  ) => void;
}

const LandingPage: React.FC<Props> = ({ _INITIATE_TRANSACTION }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const email: string | undefined | null = params.get('email');

  const errorMessage = useSelector(
    (state: any) => state.transaction.initiateTransactionError
  );

  useEffect(() => {
    if (email !== undefined && email != null) {
      localStorage.setItem('userEmail', email);
    }
  }, [email]);

  return (
    <ScrollToTop>
      <div className="landing-page">
        <div className="inner">
          <img className="top-bg" src={landingPageBg} alt="" />

          <div className="logo-container">
            <img className="logo-icon" src={logo} alt="" />
          </div>

          <div className="text-container">
            <h5>Scalex Ramp</h5>
            <p>Buy and Sell your Crypto Assets in a few clicks</p>
          </div>

          <div className="btn-control">
            <div
              onClick={() => {
                _INITIATE_TRANSACTION(navigate, 'on-ramp', 'onramp');
                ReactGA.event({
                  category: 'on-ramp',
                  action: 'Buy button click',
                  label: 'buy_button',
                });
              }}
              className="cursor"
            >
              <img src={onrampBtn} alt="" />
            </div>

            <div
              onClick={() => {
                _INITIATE_TRANSACTION(navigate, 'off-ramp', 'offramp');
                ReactGA.event({
                  category: 'off-ramp',
                  action: 'Sell button click',
                  label: 'sell_button',
                });
              }}
              className="cursor"
            >
              <img src={offrampBtn} alt="" />
            </div>
          </div>

          {errorMessage !== null && (
            <div className="landing-page-error-container">
              <div className="container-title">
                <img src={ErrorIconWithBg} alt="error-icon" />
                <h2>Service downtime</h2>
              </div>
              <p className="landing-page-error">{errorMessage}</p>
            </div>
          )}
          <Footer />
        </div>
      </div>
    </ScrollToTop>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  _INITIATE_TRANSACTION: (navigate: any, redirectTo: string, type: string) =>
    dispatch(_INITIATE_TRANSACTION(navigate, redirectTo, type)),
});

export default connect(null, mapDispatchToProps)(LandingPage);
