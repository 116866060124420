import { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Tokens,
  getNetworks,
  getParamsNetwork,
  getParamsToken,
} from '../../utils';
import ReactGA from 'react-ga';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AmountField from '../Fields/AmountFieldComponents/AmountField/AmountField';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SelectField from '../Fields/SelectFieldComponents/SelectField/SelectField';
import ScrollToTop from '../ScrollToTopComponent/ScrollToTop';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';

interface OffRampFormikProps {
  rates: any;
  supportedTokens: any;
  supportedFiat: object;
  _GET_RATES: (token: string, network: string, currency: string) => void;
}

const OffRampFormik: FC<OffRampFormikProps> = ({
  rates,
  supportedTokens,
  supportedFiat,
  _GET_RATES,
}) => {
  console.log('🚀 ~ rates:', rates);
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();

  const stage = location?.state?.stage;
  const data = location?.state?.data;

  const urlToken: string | any | null = params.get('token');
  const urlNetwork: string | any | null = params.get('network');
  const urlCurrency: string | any | null = params.get('currency');
  const urlFiat = urlCurrency !== null ? urlCurrency : '';
  const stringAmount: number | any | null = params.get('amount');
  const urlAmount = stringAmount !== null ? Number(stringAmount) : '';
  const disabledFields: string | any | null = params.get('disabledFields');

  const paramsToken = getParamsToken(supportedTokens, urlToken);
  const paramsNetwork = getParamsNetwork(supportedTokens, urlToken, urlNetwork);

  const [transactionData, setTransactionData] = useState<any | null>(
    stage != null ? data : null
  );

  const [selectedNetwork, setSelectedNetwork] = useState<string>(
    urlToken !== null && urlNetwork !== null && paramsNetwork !== undefined
      ? paramsNetwork.network
      : transactionData?.network
  );

  const [selectedToken, setSelectedToken] = useState<string>(
    urlToken !== null && paramsToken !== undefined
      ? paramsToken.symbol
      : transactionData?.token
  );

  const [selectedFiat, setSelectedFiat] = useState<string>(
    urlCurrency !== null ? urlFiat : transactionData?.currency
  );

  const networks = getNetworks(
    supportedTokens,
    selectedToken !== undefined ? selectedToken : transactionData?.token
  );

  const fees = rates?.feeInDetail?.offramp?.percentageFee;
  const gasFee = rates?.feeInDetail?.offramp?.fixedFee;
  const totalFee = rates?.feeInDetail?.offramp?.totalFee;

  const rate = rates?.offramp?.rate_in_ngn;

  const usdRate = rates?.offramp?.rate_in_usd;
  const minValueCurrencyValue = 5 * usdRate;

  const [currentStage, setCurrentStage] = useState<number>(
    stage != null
      ? stage
      : urlCurrency !== null
      ? 4
      : urlToken !== null && urlNetwork !== null
      ? 3
      : urlToken !== null
      ? 2
      : 1
  );

  const handleNextStage = (fieldPosition: number) => {
    setCurrentStage(fieldPosition + 1);
  };

  function parseDisabledFields(inputString: any) {
    const fieldsString = inputString?.replace('disabledFields: ', '');
    const fieldsArray = fieldsString?.split(',');

    return fieldsArray?.map((field: any) => field.trim());
  }

  const disabledFieldsArray = parseDisabledFields(disabledFields);

  const disabled = (value: string) => {
    const inLoop = disabledFieldsArray?.find((item: string) => item === value);
    return inLoop;
  };

  useEffect(() => {
    if (
      selectedToken !== undefined &&
      selectedNetwork !== undefined &&
      selectedFiat !== undefined
    ) {
      _GET_RATES(selectedToken, selectedNetwork, selectedFiat);
    }
  }, [_GET_RATES, selectedFiat, selectedNetwork, selectedToken]);

  return (
    <ScrollToTop>
      <Formik
        enableReinitialize
        validateOnMount
        validateOnChange={true}
        initialValues={{
          network:
            selectedNetwork !== null && selectedNetwork !== undefined
              ? selectedNetwork
              : '',
          currency: selectedFiat !== null ? selectedFiat : '',
          token:
            selectedToken !== null && selectedToken !== undefined
              ? selectedToken
              : '',
          currencyAmount:
            transactionData !== null ? transactionData?.currencyAmount : '',
          amount: urlAmount !== null ? urlAmount : '',
          accountNumber:
            transactionData !== null ? transactionData?.accountNumber : '',
          toGet: transactionData !== null ? transactionData?.toGet : '',
          fee: transactionData !== null ? transactionData?.fee : '',
          percentageFee:
            transactionData !== null ? transactionData?.percentageFee : '',
        }}
        validationSchema={Yup.object({
          token: Yup.string().required('Token is required'),
          network: Yup.string().required('This is a required field'),
          currency: Yup.string().required('Fiat currency is required'),
          amount: Yup.number()
            .typeError('Amount must be a number')
            // .min(minUsd, `Min amount is 5 ${selectedToken}.`)
            .max(10000, 'Please enter an amount not higher than $10,000.')
            .required('This is a required field'),
          currencyAmount: Yup.number()
            .typeError('Amount must be a number')
            .min(minValueCurrencyValue, `Min amount is 5 USD.`)
            .max(
              usdRate * 10000,
              'Please enter an amount not higher than $10,000.'
            )
            .required('This is a required field'),
        })}
        onSubmit={(values, { setErrors }) => {
          const errors = {};

          if (
            Object.entries(errors).length === 0 &&
            errors.constructor === Object
          ) {
            setTransactionData(values);
          } else {
            setErrors(errors);
          }
        }}
      >
        {({
          isValid,
          values: {
            fee,
            token,
            network,
            toGet,
            currency,
            amount,
            percentageFee,
            currencyAmount,
          },
          setFieldValue,
          handleSubmit,
        }) => (
          <Form className="form-control" onSubmit={handleSubmit}>
            <SelectField
              name="token"
              data={supportedTokens !== undefined ? supportedTokens : Tokens}
              rampType="off"
              fieldPosition={1}
              type={'token'}
              shortTitle="Select token"
              setSelected={setSelectedToken}
              currentStage={currentStage}
              title={'Select trading token'}
              handleNextStage={handleNextStage}
              setCurrentStage={setCurrentStage}
              setFieldValue={(value: string) => {
                setFieldValue('token', value);
                if (value !== '') {
                  ReactGA.event({
                    category: 'off-ramp',
                    action: 'select token button click',
                    label: 'select_token',
                  });
                }
              }}
              isDisabled={disabled('token')}
              reInitData={selectedToken}
            />
            <SelectField
              rampType="off"
              name="network"
              data={networks}
              type={'network'}
              fieldPosition={2}
              title={'Select network'}
              shortTitle="Select network"
              currentStage={currentStage}
              setSelected={setSelectedNetwork}
              handleNextStage={handleNextStage}
              setCurrentStage={setCurrentStage}
              setFieldValue={(value: string) => {
                setFieldValue('network', value);
                if (value !== '') {
                  ReactGA.event({
                    category: 'off-ramp',
                    action: 'select network button click',
                    label: 'select_network',
                  });
                }
              }}
              isDisabled={disabled('network')}
              reInitData={selectedNetwork}
            />
            <SelectField
              data={supportedFiat}
              rampType="off"
              type={'fiat'}
              fieldPosition={3}
              name="currency"
              shortTitle="Select currency"
              currentStage={currentStage}
              setSelected={setSelectedFiat}
              setCurrentStage={setCurrentStage}
              handleNextStage={handleNextStage}
              title={'Select cashout fiat currency'}
              setFieldValue={(value: string) => {
                setFieldValue('currency', value);
                if (value !== '') {
                  ReactGA.event({
                    category: 'off-ramp',
                    action: 'select currency button click',
                    label: 'select_currency',
                  });
                }
              }}
              isDisabled={disabled('currency')}
              reInitData={selectedFiat}
            />
            <AmountField
              rate={rate}
              usdRate={usdRate}
              crypto={token}
              rampType="off"
              percentageFee={fees}
              gasFee={gasFee}
              lastStage={true}
              fieldPosition={4}
              amountField={true}
              title={'Enter amount'}
              name="currencyAmount"
              currency={currency}
              toGet={toGet}
              isDisabled={disabled('amount')}
              currentStage={currentStage}
              setFieldValue={setFieldValue}
              selectedNetwork={selectedNetwork}
              setCurrentStage={setCurrentStage}
              totalFee={totalFee}
            />

            <PrimaryButton
              onClick={() => {
                setCurrentStage(5);
                navigate('/verify-email', {
                  state: {
                    data: {
                      fee,
                      rate,
                      token,
                      usdRate,
                      network,
                      toGet,
                      currency,
                      amount,
                      percentageFee,
                      currencyAmount,
                      gasFee,
                    },
                  },
                });

                ReactGA.event({
                  category: 'off-ramp',
                  action: 'navigate to verify email button click',
                  label: 'navigate_to_verify_email',
                });
              }}
              validForm={isValid}
              type={'button'}
              title={'Submit'}
            />
          </Form>
        )}
      </Formik>
    </ScrollToTop>
  );
};

export default OffRampFormik;
