import { useRef, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { ErrorMessage, Field, useField } from 'formik';
import { greyChevron, ngn, phoneLine } from '../../../assets';
import useEscapeKey from '../../../hooks/useEscapeKey';
import useOutsideClick from '../../../hooks/useOutsideClick';
import ImageComponent from '../../ImageComponent/ImageComponent';

interface PhoneFieldProps {
  data: any;
  style?: object;
  name: string;
  placeHolder: string;
  setFieldValue: (field: string, value: any) => void;
}

const PhoneField: FC<PhoneFieldProps> = ({
  name,
  placeHolder,
  data,
  style,
  setFieldValue,
}) => {
  const ref = useRef(null);
  const [field] = useField<any>(name);
  const [modal, setModal] = useState<boolean>(false);
  const [selectedItem, setSelected] = useState<object | any>(null);

  const handlePhoneField = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const code: string = selectedItem !== null ? selectedItem?.code : '+234';

    if (value.charAt(0) === '0') {
      const newValue = value.slice(1);
      const phone = `${code}${newValue}`;
      setFieldValue('phoneNumber', phone);
    } else {
      const phone = `${code}${value}`;
      setFieldValue('phoneNumber', phone);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleClose = () => {
    setModal(false);
  };

  useEscapeKey(handleClose);
  useOutsideClick(handleClose, ref);

  return (
    <>
      <div style={style} className="phone-number-wrapper error-border">
        <div onClick={toggleModal} ref={ref} className="flag-code-container">
          <div className="flag-wrapper">
            <img
              className="flag"
              src={selectedItem !== null ? selectedItem.logo : ngn}
              alt=""
            />
            <img className="chevron" src={greyChevron} alt="" />
          </div>
          <p>{selectedItem !== null ? selectedItem.code : '+234'}</p>
        </div>

        <div
          className={`data-modal ${
            !modal ? 'close-data-modal' : ''
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          }`}
        >
          {data?.map((obj: any, index: number) => (
            <div
              key={index}
              className="data-detail"
              onClick={() => {
                handleClose();
                setSelected(obj);
                setFieldValue('country', obj.identifier);
              }}
            >
              <ImageComponent
                size="m"
                symbol={obj.symbol !== undefined ? obj.identifier : obj.network}
                url={obj.logo !== undefined ? obj.logo : undefined}
              />
              <div className="type-details">
                <p className="title">
                  {obj.fullname} {obj.code}
                </p>
              </div>
            </div>
          ))}
        </div>

        <img src={phoneLine} alt="" />

        <Field
          className="input-field"
          type="tel"
          autoComplete="off"
          placeholder={placeHolder}
          value={field.value}
          name={field.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handlePhoneField(event);
            setFieldValue('code', event.target.value);
          }}
        />
      </div>

      <ErrorMessage name="phoneNumber" className="" />
    </>
  );
};

export default PhoneField;
